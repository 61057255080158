<template>
  <div v-if="!loading" class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress v-show="awaitBoxes" indeterminate size="2px" />
    </div>

    <div class="row q-px-md q-py-sm">
      <div class="col-24 flex justify-between items-center">
        <div class="flex q-gutter-x-md">
          <q-select
            v-model="search.related"
            :disable="awaitBoxes"
            :options="relatedFilterOptions"
            emit-value
            label="Привязанные корпуса"
            map-options
            option-label="label"
            option-value="value"
            style="min-width: 200px"
            @update:model-value="setBoxes"
          />

          <q-select
            v-model="search.box_bell_ids"
            :options="$store.state.boxBells"
            :disable="awaitBoxes"
            options-dense
            use-chips
            multiple
            map-options
            emit-value
            label="Отслеживание"
            option-label="title"
            option-value="id"
            style="min-width: 200px"
            @update:model-value="setBoxes"
          >
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps">
                <q-item-section side>
                  <q-icon
                    :name="getBoxBellIconName(scope.opt.alias)"
                    :color="getBoxBellColor(scope.opt.alias)"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ scope.opt.title }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-icon v-if="scope.selected" name="mdi-check" />
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>

        <div
          class="flex"
          :class="$q.screen.xs || $q.screen.sm ? '' : 'justify-end'"
        >
          <q-btn
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Справка'"
            flat
            icon="mdi-information-outline"
            @click="showTableNotesDialog"
          />

          <q-btn
            v-if="+$can(['box.update'])"
            icon="mdi-data-matrix-scan"
            label="Массовый парс ПД"
            @click="parsePdsMulti"
          />

          <q-btn
            v-if="+$can(['box.add'])"
            icon="mdi-plus"
            label="Добавить"
            @click="showCreateBoxDialog"
          />

          <q-btn
            icon="mdi-cog"
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Параметры'"
          >
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <q-select
                      v-model="visibleColumns"
                      :options="columns"
                      borderless
                      dense
                      display-value="Показывать столбцы"
                      emit-value
                      map-options
                      multiple
                      option-value="name"
                      options-dense
                      @update:model-value="onInputVisibleColumns"
                    >
                      <template v-slot:before>
                        <q-icon class="q-mr-sm" name="mdi-eye-check" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps">
                          <q-item-section>
                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-icon v-if="scope.selected" name="mdi-check" />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </div>

    <div class="row bg-white">
      <div class="col-24 q-px-md">
        <div
          aria-label="Корпуса"
          class="l-table l-table--sticky-first-column l-table--hoverable-rows q-pb-sm"
          role="table"
          :style="getStylesForBoxesTable()"
        >
          <div
            class="l-table__row min-w-full sticky-top bg-white"
            role="rowgroup"
            style="z-index: 2"
          >
            <template v-for="item in columns" :key="item.name">
              <div
                v-if="visibleColumns.includes(item.name)"
                :style="getCellStyle(item.name)"
                class="l-table__cell"
                role="columnheader"
              >
                <div
                  :class="item.sortName && 'cursor-pointer'"
                  class="text-body3"
                  @click="item.sortName && sortByColumn(item.sortName)"
                >
                  {{ item.label }}
                  <q-icon
                    v-if="pagination.sortBy === item.sortName"
                    :name="
                      pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
                    "
                  />
                </div>
              </div>
            </template>
          </div>

          <BoxTableFiltersRow
            :cellStyles="cellStyles"
            :search="search"
            :visibleColumns="visibleColumns"
            @filter-boxes="filterBoxes"
            @set-boxes="setBoxes"
          />

          <div
            v-for="box in boxes"
            :key="box.id"
            :class="checkBoxHasGreenMark(box) ? 'l-table__row--positive' : ''"
            class="l-table__row l-table__row-content min-w-full wrap"
            role="rowgroup"
          >
            <q-menu
              auto-close
              context-menu
              touch-position
              transition-hide=""
              transition-show=""
            >
              <q-list separator>
                <q-item clickable @click="toggleExpandedRow(box.id)">
                  <q-item-section v-if="!expandedRows.includes(box.id)"
                    >Показать корпуса АП
                  </q-item-section>
                  <q-item-section v-else>Скрыть корпуса АП</q-item-section>
                </q-item>

                <q-item class="q-pa-none" clickable>
                  <a
                    :href="getBuildingLink(box.serial)"
                    :title="box.name"
                    class="l-link flex self-stretch items-center cursor-pointer text-black q-px-md"
                    target="_blank"
                  >
                    Открыть в ДомРФ
                  </a>
                </q-item>

                <q-item clickable @click="expandedRows = []">
                  <q-item-section>Скрыть все</q-item-section>
                </q-item>

                <q-item>
                  <q-item-section>
                    <span
                      >Парс ПД:
                      <span v-if="box.pd_parse_status == 'ok'">✅</span
                      ><span v-if="box.pd_parse_status == 'error'"
                        >⛔</span
                      ></span
                    >
                  </q-item-section>
                </q-item>

                <q-item>
                  <q-item-section>
                    <span
                      >Парс Карточка:
                      <span v-if="box.parse_status == 'ok'">✅</span
                      ><span v-if="box.parse_status == 'error'">⛔</span></span
                    >
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>

            <div class="l-table__row min-w-full" role="rowgroup">
              <div
                v-if="visibleColumns.includes('id')"
                :style="getCellStyle('id')"
                class="l-table__cell"
              >
                {{ box.id }}
              </div>

              <div
                v-if="visibleColumns.includes('serial')"
                :style="getCellStyle('serial')"
                class="l-table__cell"
              >
                {{ box.serial }}
                <q-btn
                  :href="getBuildingLink(box.serial)"
                  alt="Перейти на страницу корпуса"
                  flat
                  dense
                  size="sm"
                  icon="mdi-open-in-new"
                  target="_blank"
                  type="a"
                />
              </div>

              <div
                v-if="visibleColumns.includes('region_name')"
                :style="getCellStyle('region_name')"
                class="l-table__cell"
              >
                {{ box.region_name }}
              </div>

              <div
                v-if="visibleColumns.includes('name')"
                :style="getCellStyle('name')"
                :title="box.name"
                class="l-table__cell"
              >
                {{ shortness(box.name, 80) }}
              </div>

              <div
                v-if="visibleColumns.includes('address')"
                :style="getCellStyle('address')"
                :title="box.address"
                class="l-table__cell"
              >
                {{ shortness(box.address, 80) }}
              </div>

              <div
                v-if="visibleColumns.includes('coordinates')"
                :style="getCellStyle('coordinates')"
                class="l-table__cell"
              >
                {{
                  box.coordinates &&
                  box.coordinates.length &&
                  box.coordinates.join(" ")
                }}
              </div>

              <div
                v-if="visibleColumns.includes('builder')"
                :style="getCellStyle('builder')"
                class="l-table__cell"
              >
                {{ box.builder }}
              </div>

              <div
                v-if="visibleColumns.includes('developer')"
                :style="getCellStyle('developer')"
                class="l-table__cell"
              >
                {{ box.developer }}
              </div>

              <div
                v-if="visibleColumns.includes('pd_file_external_url')"
                :style="getCellStyle('pd_file_external_url')"
                class="l-table__cell"
              >
                <div v-if="box.pd_files && box.pd_files.length">
                  <a
                    :href="box.pd_files[0].external_url"
                    class="l-link"
                    target="_blank"
                    >ссылка</a
                  >
                </div>
              </div>

              <div
                v-if="visibleColumns.includes('floor_max')"
                :style="getCellStyle('floor_max')"
                class="l-table__cell"
              >
                {{ box.floor_max }}
              </div>

              <div
                v-if="visibleColumns.includes('flat_qty')"
                :style="getCellStyle('flat_qty')"
                class="l-table__cell"
              >
                {{ box.flat_qty }}
              </div>

              <div
                v-if="visibleColumns.includes('parking_qty')"
                :style="getCellStyle('parking_qty')"
                class="l-table__cell"
              >
                {{ box.parking_qty }}
              </div>

              <div
                v-if="visibleColumns.includes('date_rve')"
                :style="getCellStyle('date_rve')"
                class="l-table__cell"
              >
                {{ box.date_rve }}
              </div>

              <div
                v-if="visibleColumns.includes('date_rve_received')"
                :style="getCellStyle('date_rve_received')"
                class="l-table__cell"
              >
                {{ box.date_rve_received }}
              </div>

              <div
                v-if="visibleColumns.includes('sold_out_percent')"
                :style="getCellStyle('sold_out_percent')"
                class="l-table__cell"
              >
                {{ box.sold_out_percent }}
              </div>

              <div
                v-if="visibleColumns.includes('estate_class')"
                :style="getCellStyle('estate_class')"
                class="l-table__cell"
              >
                {{ box.estate_class }}
              </div>

              <div
                v-if="visibleColumns.includes('finishing_type')"
                :style="getCellStyle('finishing_type')"
                class="l-table__cell"
              >
                {{ box.finishing_type }}
              </div>

              <div
                v-if="visibleColumns.includes('living_square')"
                :style="getCellStyle('living_square')"
                class="l-table__cell"
              >
                {{ box.living_square }}
              </div>

              <div
                v-if="visibleColumns.includes('ceiling_height')"
                :style="getCellStyle('ceiling_height')"
                class="l-table__cell"
              >
                {{ getCeilingHeight(box.ceiling_height) }}
              </div>

              <div
                v-if="visibleColumns.includes('wall_type')"
                :style="getCellStyle('wall_type')"
                class="l-table__cell"
              >
                {{ box.wall_type }}
              </div>

              <div
                v-if="visibleColumns.includes('parsed_at')"
                :style="getCellStyle('parsed_at')"
                :class="getStatusCellClass(box)"
              >
                {{ box.parsed_at }}
              </div>

              <div
                v-if="visibleColumns.includes('will_parsed_at')"
                :style="getCellStyle('will_parsed_at')"
                class="l-table__cell"
              >
                {{ box.will_parsed_at }}
              </div>

              <div
                v-if="visibleColumns.includes('pd_order')"
                :style="getCellStyle('pd_order')"
                class="l-table__cell"
              >
                {{ box.pd_order }}
              </div>

              <div
                v-if="visibleColumns.includes('comment')"
                :style="getCellStyle('comment')"
                class="l-table__cell"
              >
                <div v-if="typeof box.comments[0] !== 'undefined'">
                  {{ box.comments[0].comment }}
                </div>
              </div>

              <div
                v-if="visibleColumns.includes('cadastrals')"
                :style="getCellStyle('cadastrals')"
                class="l-table__cell"
              >
                {{ box.cadastrals && box.cadastrals.join(", ") }}
              </div>

              <div
                v-if="visibleColumns.includes('pds')"
                :style="getCellStyle('pds')"
                class="l-table__cell"
              >
                {{ box.pds && box.pds.length }}
                <q-icon
                  v-if="box.pds && box.pds.length"
                  name="mdi-eye-outline"
                  color="primary"
                  size="xs"
                  class="q-ml-xs cursor-pointer"
                  @click="showBoxPdsDialog(box)"
                />
              </div>

              <div
                v-if="visibleColumns.includes('ap_project_serial')"
                :style="getCellStyle('ap_project_serial')"
                class="l-table__cell"
              >
                {{ box.ap_project_serial }}
              </div>

              <div
                v-if="visibleColumns.includes('created_at')"
                :style="getCellStyle('created_at')"
                class="l-table__cell"
              >
                {{ box.created_at }}
              </div>

              <div
                v-if="visibleColumns.includes('actions')"
                :style="getCellStyle('actions')"
                class="l-table__cell"
              >
                <div class="flex items-center no-wrap">
                  <q-btn
                    :icon="
                      expandedRows.includes(box.id)
                        ? 'mdi-chevron-up'
                        : 'mdi-chevron-down'
                    "
                    flat
                    @click="toggleExpandedRow(box.id)"
                  />

                  <q-checkbox
                    v-model="selectedBoxIds"
                    :disable="awaitParsePds"
                    :val="box.id"
                  />

                  <q-btn
                    flat
                    :disabled="box.ap_boxes"
                    :title="!box.ap_boxes ? 'Изменить' : 'Изменить нельзя'"
                    :icon="getBoxBellIconName(box.box_bell?.alias)"
                    :color="getBoxBellColor(box.box_bell?.alias)"
                  >
                    <q-popup-edit
                      v-if="!box.ap_boxes"
                      v-model="box.box_bell"
                      auto-save
                      v-slot="scope"
                      @save="(item) => onUpdateBoxBell(box.id, item.id)"
                    >
                      <q-select
                        v-model="scope.value"
                        :options="$store.state.boxBells"
                        options-dense
                        dense
                        autofocus
                        option-label="title"
                        @keyup.enter="scope.set"
                      >
                        <template v-slot:selected-item="scope">
                          <q-icon
                            :name="getBoxBellIconName(scope.opt.alias)"
                            :color="getBoxBellColor(scope.opt.alias)"
                            class="q-mr-sm"
                          />
                          <span>{{ scope.opt.title }}</span>
                        </template>

                        <template v-slot:option="scope">
                          <q-item v-bind="scope.itemProps">
                            <q-item-section side>
                              <q-icon
                                :name="getBoxBellIconName(scope.opt.alias)"
                                :color="getBoxBellColor(scope.opt.alias)"
                              />
                            </q-item-section>
                            <q-item-section>
                              <q-item-label>{{ scope.opt.title }}</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                              <q-icon v-if="scope.selected" name="mdi-check" />
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </q-popup-edit>
                  </q-btn>

                  <q-btn
                    :disable="awaitParsePds"
                    flat
                    icon="mdi-data-matrix-scan"
                    title="Запустить парс ПД файлов"
                    @click="parsePds(box)"
                  />

                  <q-btn
                    :disable="
                      awaitReCompare || !(box.ap_boxes && box.ap_boxes.length)
                    "
                    flat
                    icon="mdi-chart-bar-stacked"
                    title="Запустить пересчет сравнения"
                    @click="reCompare(box.id)"
                  />

                  <q-btn
                    :disable="awaitReParse"
                    flat
                    icon="mdi-refresh"
                    title="Парс карточки с сайта"
                    @click="reParse(box.id)"
                  />

                  <q-btn
                    v-if="+$can(['box.update'])"
                    :to="{ name: 'BoxEdit', params: { id: box.id } }"
                    alt="Редактировать корпус"
                    flat
                    icon="mdi-pencil"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="expandedRows.includes(box.id)"
              class="l-table__row min-w-full bg-grey-2 inset-shadow"
              role="rowgroup"
            >
              <ExpandedApBoxRow :apBoxes="box.ap_boxes" :boxId="box.id" />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!boxes || (Array.isArray(boxes) && boxes.length === 0)"
        class="q-pa-md"
      >
        Нет данных
      </div>

      <div
        v-if="boxes && boxes.length"
        class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white sticky-bottom shadow-up-3"
        style="z-index: 3"
      >
        <div class="text-body3 q-mr-md">
          Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
        </div>

        <q-pagination
          v-model="pagination.page"
          :disable="awaitBoxes"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          @update:model-value="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import lf from "@/plugins/localforage";
  import api from "@/api";
  import emitter from "@/plugins/mitt";
  import {
    normalizeQueryForRequest,
    getDomRfBuildingLink,
    formatCeilingHeight,
    boxBellIconName,
    boxBellColor,
  } from "@/utils/batch";
  import { shortness } from "@/plugins/filters";
  import { createMetaMixin } from "quasar";
  import BoxTableFiltersRow from "@/components/boxes/BoxTableFiltersRow";
  import CreateBoxDialog from "@/components/dialogs/CreateBoxDialog";
  import BoxPdsDialog from "@/components/dialogs/BoxPdsDialog";
  import ExpandedApBoxRow from "@/components/boxes/ExpandedApBoxRow";
  import BoxParsePdsDialog from "@/components/dialogs/BoxParsePdsDialog";

  export default {
    name: "BoxList",

    components: {
      BoxTableFiltersRow,
      ExpandedApBoxRow,
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Корпуса ДомРФ",
        };
      }),
    ],

    created() {
      emitter.on("ap-boxes-linked", this.setBoxes);
      emitter.on("ap-box-unlinked", this.setBoxes);
    },

    async mounted() {
      // filter by permissions or something else
      this.columns = this.columns.filter((item) => item.visible);
      await this.checkVisibleColumns();
      this.updateColumnStyles(this.columns);

      let res = await api.utils.parseInfo();

      if (res.status === 200) {
        this.parseInfo = res.data;
        if (this.parseInfo.two_week_outdated > 2000) {
          this.$q.notify({
            color: "warning",
            type: "warning",
            timeout: 15000,
            html: true,
            closeBtn: true,
            message:
              this.parseInfo.two_week_outdated +
              " корпусов отстают от расписания парсинга" +
              "<br><small>(Где дата след. парсинга ≤ 2 недели назад)</small>",
          });
        }
      }

      this.loading = false;
    },

    unmounted() {
      emitter.off("ap-boxes-linked", this.setBoxes);
      emitter.off("ap-box-unlinked", this.setBoxes);
    },

    data() {
      return {
        loading: true,
        awaitBoxes: false,
        awaitReCompare: false,
        awaitReParse: false,
        awaitParsePds: false,
        boxes: [],
        expandedRows: [],
        selectedBoxIds: [],
        parseInfo: [],
        pagination: {
          rowsNumber: null,
          rowsPerPage: 25,
          sortBy: "created_at",
          descending: true,
          page: 1,
        },
        visibleColumns: [
          "id",
          "serial",
          "region_name",
          "name",
          "address",
          "builder",
          "date_rve",
          "date_rve_received",
          "sold_out_percent",
          "living_square",
          "parsed_at",
          "will_parsed_at",
          "pd_order",
          "created_at",
          "actions",
        ],
        columns: [
          {
            name: "id",
            label: "ID",
            visible: true,
            sortName: "id",
            style: "width: 80px;",
          },
          {
            name: "serial",
            label: "Номер",
            visible: true,
            sortName: "serial",
            style: "width: 100px;",
          },
          {
            name: "region_name",
            label: "Регион",
            visible: true,
            sortName: "region_name",
            style: "width: 160px;",
          },
          {
            name: "name",
            label: "Название",
            visible: true,
            sortName: "name",
            style: "width: 160px;",
          },
          {
            name: "address",
            label: "Адрес",
            visible: true,
            sortName: "address",
            style: "width: 300px;",
          },
          {
            name: "coordinates",
            label: "Координаты",
            visible: true,
            style: "width: 160px;",
          },
          {
            name: "builder",
            label: "Застройщик",
            visible: true,
            sortName: "builder",
            style: "width: 160px;",
          },
          {
            name: "developer",
            label: "Девелопер",
            visible: true,
            sortName: "developer",
            style: "width: 160px;",
          },
          {
            name: "pd_file_external_url",
            label: "Файл пд домрф",
            visible: true,
            style: "width: 160px;",
          },
          {
            name: "floor_max",
            label: "Этажность",
            visible: true,
            sortName: "floor_max",
            style: "width: 100px;",
          },
          {
            name: "flat_qty",
            label: "Квартир",
            visible: true,
            sortName: "flat_qty",
            style: "width: 100px;",
          },
          {
            name: "parking_qty",
            label: "Машиномест",
            visible: true,
            sortName: "parking_qty",
            style: "width: 100px;",
          },
          {
            name: "date_rve",
            label: "РВЭ",
            visible: true,
            sortName: "date_rve",
            style: "width: 140px;",
          },
          {
            name: "date_rve_received",
            label: "РВЭ получено",
            visible: true,
            sortName: "date_rve_received",
            style: "width: 140px;",
          },
          {
            name: "sold_out_percent",
            label: "% Распроданности",
            visible: true,
            sortName: "sold_out_percent",
            style: "width: 140px;",
          },
          {
            name: "estate_class",
            label: "Класс",
            visible: true,
            sortName: "estate_class",
            style: "width: 160px;",
          },
          {
            name: "finishing_type",
            label: "Отделка",
            visible: true,
            sortName: "finishing_type",
            style: "width: 160px;",
          },
          {
            name: "living_square",
            label: "Жилая площадь",
            visible: true,
            sortName: "living_square",
            style: "width: 100px;",
          },
          {
            name: "ceiling_height",
            label: "Высота потолков",
            visible: true,
            style: "width: 120px;",
          },
          {
            name: "wall_type",
            label: "Стены",
            visible: true,
            sortName: "wall_type",
            style: "width: 160px;",
          },
          {
            name: "parsed_at",
            label: "Парсинг",
            visible: true,
            sortName: "parsed_at",
            style: "width: 160px;",
          },
          {
            name: "will_parsed_at",
            label: "Парсинг следующий",
            visible: true,
            sortName: "will_parsed_at",
            style: "width: 160px;",
          },
          {
            name: "pd_order",
            label: "Номер объекта в ПД",
            visible: true,
            sortName: "pd_order",
            style: "width: 160px;",
          },

          {
            name: "comment",
            label: "Комментарий",
            visible: true,

            style: "width: 160px;",
          },
          {
            name: "cadastrals",
            label: "Кадастр",
            visible: true,
            style: "width: 160px;",
          },
          {
            name: "pds",
            label: "ПД, шт",
            visible: true,
            style: "width: 160px;",
          },
          {
            name: "ap_project_serial",
            label: "АП проект",
            visible: true,
            style: "width: 160px;",
          },
          {
            name: "created_at",
            label: "Создан",
            visible: true,
            sortName: "created_at",
            style: "width: 160px;",
          },
          {
            name: "actions",
            label: "Действия",
            visible: true,
            style: "width: 385px;",
          },
        ],
        search: {
          id: { c: "eq", v: null },
          serial: { c: "ctn", v: null },
          region_name: { c: "ctn", v: null },
          name: { c: "ctn", v: null },
          address: { c: "ctn", v: null },
          coordinates: null,
          builder: { c: "ctn", v: null },
          developer: { c: "ctn", v: null },
          floor_max: { c: "eq", v: null },
          flat_qty: { c: "eq", v: null },
          parking_qty: { c: "eq", v: null },
          date_rve: {
            from: null,
            to: null,
          },
          date_rve_received: {
            from: null,
            to: null,
          },
          sold_out_percent: {
            from: null,
            to: null,
          },
          estate_class: { c: "ctn", v: null },
          finishing_type: { c: "ctn", v: null },
          living_square: { c: "eq", v: null },
          ceiling_height: null,
          wall_type: { c: "ctn", v: null },
          parsed_at: {
            from: null,
            to: null,
          },
          will_parsed_at: {
            from: null,
            to: null,
          },
          pd_order: { c: "eq", v: null },
          created_at: {
            from: null,
            to: null,
          },
          related: null,
          cadastrals: null,
          box_bell_ids: [],
          ap_project_serial: { c: "ctn", v: null },
        },
        cellStyles: {},
      };
    },

    computed: {
      relatedFilterOptions() {
        return [
          { label: "Не важно", value: null },
          { label: "Нет", value: "negative" },
          { label: "Есть", value: "positive" },
        ];
      },
    },

    methods: {
      filterBoxes() {
        this.setBoxes(true);
      },

      async setBoxes(isFiltering = false) {
        this.awaitBoxes = true;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        let res = await api.box.find(
          paginateOptions,
          "ap_boxes,pd_files,box_bell,comments"
        );

        if (res.status === 200 && res.data.boxes) {
          this.boxes = res.data.boxes;
          this.pagination.rowsNumber = res.data.meta.pagination.total;
        }

        if (res.status === 204) {
          this.boxes = [];
        }

        this.awaitBoxes = false;
      },

      sortByColumn(field) {
        this.pagination.sortBy = field;
        this.pagination.descending = !this.pagination.descending;

        this.setBoxes();
      },

      onPaginationInput(page) {
        this.setBoxes();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },

      async checkVisibleColumns() {
        let visibleColumns = await lf.getItem("boxes_table_visible_columns");

        if (!visibleColumns) {
          return;
        }

        const columnNames = this.columns.map((item) => item.name);

        // remove old column names
        visibleColumns.forEach((name, index) => {
          if (!columnNames.includes(name)) {
            delete visibleColumns[index];
          }
        });

        this.visibleColumns = visibleColumns;
        await this.saveVisibleColumnsInStorage(visibleColumns);
      },

      async saveVisibleColumnsInStorage(values) {
        try {
          await lf.setItem("boxes_table_visible_columns", values);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }
      },

      onInputVisibleColumns(values) {
        this.updateColumnStyles(this.columns);
        this.saveVisibleColumnsInStorage(values);
      },

      showCreateBoxDialog() {
        this.$q.dialog({
          component: CreateBoxDialog,
        });
      },

      async onUpdateBoxBell(boxId, boxBellId) {
        await api.box.updateBell(boxId, boxBellId);
        await this.setBoxes();
      },

      parsePds(box) {
        this.$q.dialog({
          component: BoxParsePdsDialog,
          componentProps: {
            box: box,
            queryRowsQty: this.pagination.rowsNumber,
          },
        });
      },

      parsePdsMulti() {
        this.$q.dialog({
          component: BoxParsePdsDialog,
          componentProps: {
            ids: this.selectedBoxIds,
            query: normalizeQueryForRequest(this.search),
            queryRowsQty: this.pagination.rowsNumber,
          },
        });
      },

      checkBoxHasGreenMark(box) {
        if (box.ap_boxes && box.ap_boxes.length) {
          return false;
        }

        return !box.skip;
      },

      showTableNotesDialog() {
        this.$q.dialog({
          title: "Пояснения к таблице",
          message:
            "Зеленая подсветка строки - корпус не имеет привязанных АП корпусов," +
            ' т.е. корпус считается "новым". Если поставить перечекрнутый колокольчик, то' +
            " такой корпус перестанет считаться новым.",
        });
      },

      showBoxPdsDialog(box) {
        this.$q.dialog({
          component: BoxPdsDialog,
          componentProps: {
            serial: box.serial,
            pds: box.pds,
          },
        });
      },

      toggleExpandedRow(id) {
        if (this.expandedRows.includes(id)) {
          let index = this.expandedRows.indexOf(id);
          this.expandedRows.splice(index, 1);
        } else {
          this.expandedRows.push(id);
        }
      },

      getBuildingLink(serial) {
        return getDomRfBuildingLink(serial);
      },

      reCompare(boxId) {
        this.awaitReCompare = true;

        api.box
          .reCompare(boxId)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .finally(() => {
            this.awaitReCompare = false;
          });
      },

      reParse(boxId) {
        this.awaitReParse = true;

        api.box
          .reParse(boxId)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .finally(() => {
            this.awaitReParse = false;
          });
      },

      shortness(value, length) {
        return shortness(value, length);
      },

      getStylesForBoxesTable() {
        if (this.$q.screen.xs || this.$q.screen.sm) {
          return "";
        }

        const height =
          this.$store.state.windowInnerHeight - (this.$q.screen.md ? 245 : 210);

        return `max-height: ${height}px`;
      },

      getCeilingHeight(value) {
        return formatCeilingHeight(value);
      },

      getBoxBellColor(alias) {
        return boxBellColor(alias);
      },

      getBoxBellIconName(alias) {
        return boxBellIconName(alias);
      },

      getStatusCellClass(box) {
        if (box.pd_parse_status === "error" || box.parse_status === "error") {
          return "l-table__cell l-table__cell--negative";
        }
        return "l-table__cell";
      },
    },
  };
</script>
