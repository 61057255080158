<template>
  <div class="flex justify-center text-body2 bg-grey-2 q-py-xs">
    <q-breadcrumbs separator-color="grey-10">
      <q-breadcrumbs-el
        v-for="(crumb, i) in crumbs"
        :key="i"
        :label="crumb.name"
        :to="crumb.path"
        exact-active-class="l-link l-link--exact"
      />
    </q-breadcrumbs>
  </div>
</template>

<script>
  export default {
    name: "Breadcrumbs",

    computed: {
      crumbs() {
        let path = "";
        let crumbs = [];

        if (!this.$route) {
          return [];
        }

        const originalCrumbs = this.$route.path.split("/");
        const lastMatched = this.$route.matched.slice(-1)[0] || {};
        const routeCrumbs =
          Object.keys(lastMatched).length > 0 ? lastMatched.meta.crumbs : "";
        const metaCrumbs = routeCrumbs ? lastMatched.meta.crumbs : "";
        const splitMatched = metaCrumbs.split("/");

        for (let i = 1; i < originalCrumbs.length; i++) {
          let originalName = originalCrumbs[i];
          let customName = splitMatched[i];

          let name = customName || originalName;

          // dynamic params
          if (customName === "{:}") {
            name = originalName;
          }

          if (originalName === "") {
            continue;
          }

          path += "/" + originalName;

          crumbs.push({ name: name, path: path });
        }

        return crumbs;
      },
    },
  };
</script>
