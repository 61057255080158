<template>
  <div class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress v-show="loading" indeterminate size="2px" />
    </div>

    <div class="row bg-white">
      <div class="col-24 flex items-center q-px-md">
        <q-select
          v-model="search.reviewed"
          :disable="loading"
          :options="reviewedFilterOptions"
          clearable
          emit-value
          label="Проверено"
          map-options
          option-label="label"
          option-value="value"
          style="min-width: 200px"
          @update:model-value="setCompounds(false)"
        />

        <q-btn
          :disable="awaitExportExcel || !compounds.length"
          :loading="awaitExportExcel"
          icon="mdi-file-download-outline"
          :label="$q.screen.xs || $q.screen.sm ? 'XLS' : 'Excel'"
          @click="exportExcel"
          style="margin-left: 20px"
        />
      </div>

      <div class="col-24 q-px-md q-pt-md">
        <div
          aria-label="Сравнение"
          class="l-table l-table--sticky-first-column"
          role="table"
          :style="getStylesForCompareTable()"
        >
          <CompareTableTopFiltersRow
            :cellStyles="cellStyles"
            :search="search"
            @filter-compounds="filterCompounds"
          />

          <div
            class="l-table__row bg-white sticky-top"
            role="rowgroup"
            style="z-index: 2"
          >
            <div
              v-for="item in columns"
              :key="item.name"
              :style="getCellStyle(item.name)"
              class="l-table__cell"
              role="columnheader"
            >
              <div
                :class="item.sortName && 'cursor-pointer'"
                class="text-body3"
                @click="item.sortName && sortByColumn(item.sortName)"
              >
                {{ item.label }}
                <q-icon
                  v-if="pagination.sortBy === item.sortName"
                  :name="
                    pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
                  "
                />
              </div>
            </div>
          </div>

          <CompareTableFiltersRow
            :cellStyles="cellStyles"
            :search="search"
            @set-compounds="setCompounds"
            @filter-compounds="filterCompounds"
          />

          <div
            v-for="compound in compounds"
            :key="compound.id"
            role="rowgroup"
            style="width: fit-content"
          >
            <q-menu
              auto-close
              context-menu
              touch-position
              transition-hide=""
              transition-show=""
            >
              <q-list separator>
                <q-item clickable @click="toggleExpandedRow(compound.id)">
                  <q-item-section v-if="!expandedRows.includes(compound.id)"
                    >Показать корпуса
                  </q-item-section>
                  <q-item-section v-else>Скрыть корпуса</q-item-section>
                </q-item>

                <q-item clickable @click="expandedRows = []">
                  <q-item-section>Скрыть все</q-item-section>
                </q-item>
              </q-list>
            </q-menu>

            <div class="l-table__row" role="rowgroup">
              <div :style="getCellStyle('serial')" class="l-table__cell">
                {{ getSerialField(compound) }}
              </div>

              <div :style="getCellStyle('region_name')" class="l-table__cell">
                {{
                  compound.extra.region_name &&
                  compound.extra.region_name.join(", ")
                }}
              </div>

              <div :style="getCellStyle('project')" class="l-table__cell">
                {{
                  compound.extra.project && compound.extra.project.join(", ")
                }}
              </div>

              <div
                :class="getCellClass('builder', compound)"
                :style="getCellStyle('builder')"
                class="l-table__cell"
              >
                {{ compound.builder && compound.builder.join(", ") }}
                <q-tooltip
                  v-if="showCompareTooltip(compound, 'builder')"
                  v-bind="tooltipBinds"
                >
                  {{ compound.compared.builder.reason }}
                </q-tooltip>
              </div>

              <div
                :class="getCellClass('developer', compound)"
                :style="getCellStyle('developer')"
                class="l-table__cell"
              >
                {{ compound.developer && compound.developer.join(", ") }}
                <q-tooltip
                  v-if="showCompareTooltip(compound, 'developer')"
                  v-bind="tooltipBinds"
                >
                  {{ compound.compared.developer.reason }}
                </q-tooltip>
              </div>

              <div
                :class="getCellClass('floor_max', compound)"
                :style="getCellStyle('floor_max')"
                class="l-table__cell"
              >
                {{ compound.floor_max && compound.floor_max.join(", ") }}
                <q-tooltip
                  v-if="showCompareTooltip(compound, 'floor_max')"
                  v-bind="tooltipBinds"
                >
                  {{ compound.compared.floor_max.reason }}
                </q-tooltip>
              </div>

              <div
                :class="getCellClass('flat_qty', compound)"
                :style="getCellStyle('flat_qty')"
                class="l-table__cell"
              >
                {{ compound.flat_qty && compound.flat_qty.join(", ") }}
                <q-tooltip
                  v-if="showCompareTooltip(compound, 'flat_qty')"
                  v-bind="tooltipBinds"
                >
                  {{ compound.compared.flat_qty.reason }}
                </q-tooltip>
              </div>

              <div
                :class="getCellClass('parking_qty', compound)"
                :style="getCellStyle('parking_qty')"
                class="l-table__cell"
              >
                {{ compound.parking_qty && compound.parking_qty.join(", ") }}
                <q-tooltip
                  v-if="showCompareTooltip(compound, 'parking_qty')"
                  v-bind="tooltipBinds"
                >
                  {{ compound.compared.parking_qty.reason }}
                </q-tooltip>
              </div>

              <div
                :class="getCellClass('date_rve', compound)"
                :style="getCellStyle('date_rve')"
                class="l-table__cell"
              >
                {{ compound.date_rve && compound.date_rve.join(", ") }}
                <q-tooltip
                  v-if="showCompareTooltip(compound, 'date_rve')"
                  v-bind="tooltipBinds"
                >
                  {{ compound.compared.date_rve.reason }}
                </q-tooltip>
              </div>

              <div
                :class="getCellClass('ceiling_height', compound)"
                :style="getCellStyle('ceiling_height')"
                class="l-table__cell pre-line"
              >
                {{ getCeilingHeight(compound.ceiling_height) }}
                <q-tooltip
                  v-if="showCompareTooltip(compound, 'ceiling_height')"
                  v-bind="tooltipBinds"
                >
                  {{ compound.compared.ceiling_height.reason }}
                </q-tooltip>
              </div>

              <div
                :class="getCellClass('finishing_type', compound)"
                :style="getCellStyle('finishing_type')"
                class="l-table__cell"
              >
                {{
                  compound.finishing_type && compound.finishing_type.join(", ")
                }}
                <q-tooltip
                  v-if="showCompareTooltip(compound, 'finishing_type')"
                  v-bind="tooltipBinds"
                >
                  {{ compound.compared.finishing_type.reason }}
                </q-tooltip>
              </div>

              <div
                :class="getCellClass('living_square', compound)"
                :style="getCellStyle('living_square')"
                class="l-table__cell"
              >
                {{
                  compound.living_square && compound.living_square.join(", ")
                }}
                <q-tooltip
                  v-if="showCompareTooltip(compound, 'living_square')"
                  v-bind="tooltipBinds"
                >
                  {{ compound.compared.living_square.reason }}
                </q-tooltip>
              </div>

              <div
                :class="getCellClass('cadastrals', compound)"
                :style="getCellStyle('cadastrals')"
                class="l-table__cell"
              >
                {{ compound.cadastrals && compound.cadastrals.join(", ") }}
                <q-tooltip
                  v-if="showCompareTooltip(compound, 'cadastrals')"
                  v-bind="tooltipBinds"
                >
                  {{ compound.compared.cadastrals.reason }}
                </q-tooltip>
              </div>

              <div :style="getCellStyle('reviewed_by')" class="l-table__cell">
                {{
                  compound.compound_static &&
                  compound.compound_static.reviewer &&
                  compound.compound_static.reviewer.name
                }}
              </div>

              <div :style="getCellStyle('reviewed_at')" class="l-table__cell">
                {{
                  compound.compound_static &&
                  compound.compound_static.reviewed_at
                }}
              </div>

              <div
                :style="getCellStyle('reviewed_comment')"
                class="l-table__cell"
              >
                <div v-if="compound.compound_static">
                  <span style="word-break: break-word">{{
                    shortness(compound.compound_static.reviewed_comment, 100)
                  }}</span>
                  <q-tooltip
                    v-if="compound.compound_static.reviewed_comment"
                    v-bind="tooltipBinds"
                  >
                    {{ compound.compound_static.reviewed_comment }}
                  </q-tooltip>
                </div>
              </div>

              <div :style="getCellStyle('actions')" class="l-table__cell">
                <q-btn
                  :icon="
                    expandedRows.includes(compound.id)
                      ? 'mdi-chevron-up'
                      : 'mdi-chevron-down'
                  "
                  flat
                  title="Показать группы корпусов"
                  @click="toggleExpandedRow(compound.id)"
                />

                <q-btn
                  flat
                  icon="mdi-pencil"
                  title="Редактировать"
                  @click="showUpdateCompoundDialog(compound)"
                />

                <q-btn
                  flat
                  icon="mdi-check"
                  title="Закрыть"
                  @click="reviewCompound(compound.id)"
                />
              </div>
            </div>

            <div
              v-show="expandedRows.includes(compound.id)"
              class="l-table__row bg-grey-3 inset-shadow"
              role="rowgroup"
            >
              <ExpandedCompareRow
                :apBoxBlend="compound.ap_box_blend"
                :boxBlend="compound.box_blend"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          !compounds || (Array.isArray(compounds) && compounds.length === 0)
        "
        class="q-pa-md"
      >
        Нет данных
      </div>

      <div
        v-if="compounds && compounds.length"
        class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white sticky-bottom shadow-up-3"
        style="z-index: 2"
      >
        <div class="text-body3 q-mr-md">
          Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
        </div>

        <q-pagination
          v-model="pagination.page"
          :disable="loading"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          @update:model-value="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    formatCeilingHeightArray,
    normalizeQueryForRequest,
    getFormattedLocalDateTimeString,
  } from "@/utils/batch";
  import CompareTableFiltersRow from "@/components/compare/CompareTableFiltersRow";
  import ExpandedCompareRow from "@/components/compare/ExpandedCompareRow";
  import UpdateCompoundDialog from "@/components/dialogs/UpdateCompoundDialog";
  import CompareTableTopFiltersRow from "@/components/compare/CompareTableTopFiltersRow";
  import api from "@/api";
  import { shortness } from "@/plugins/filters";
  import { createMetaMixin, exportFile } from "quasar";

  export default {
    name: "Compare",

    components: {
      CompareTableTopFiltersRow,
      CompareTableFiltersRow,
      ExpandedCompareRow,
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Сравнение",
        };
      }),
    ],

    data() {
      return {
        loading: false,
        compounds: [],
        awaitExportExcel: false,
        expandedRows: [],
        pagination: {
          rowsNumber: null,
          rowsPerPage: 25,
          sortBy: "extra.region_name",
          descending: true,
          page: 1,
        },
        columns: [
          {
            name: "serial",
            label: "Номера",
            style: "width: 120px;",
          },
          {
            name: "region_name",
            label: "Регион",
            sortName: "extra.region_name",
            style: "width: 160px;",
          },
          {
            name: "project",
            label: "Проект",
            sortName: "extra.project",
            style: "width: 160px;",
          },
          {
            name: "builder",
            label: "Застройщик",
            sortName: "builder",
            style: "width: 200px;",
          },
          {
            name: "developer",
            label: "Девелопер",
            sortName: "developer",
            style: "width: 200px;",
          },
          {
            name: "floor_max",
            label: "Этажность",
            sortName: "floor_max",
            style: "width: 100px;",
          },
          {
            name: "flat_qty",
            label: "Квартир",
            sortName: "flat_qty",
            style: "width: 100px;",
          },
          {
            name: "parking_qty",
            label: "Машиномест",
            sortName: "parking_qty",
            style: "width: 110px;",
          },
          {
            name: "date_rve",
            label: "РВЭ",
            sortName: "date_rve",
            style: "width: 160px;",
          },
          {
            name: "ceiling_height",
            label: "Высота потолков",
            sortName: "ceiling_height",
            style: "width: 120px;",
          },
          {
            name: "finishing_type",
            label: "Отделка",
            sortName: "finishing_type",
            style: "width: 160px;",
          },
          {
            name: "living_square",
            label: "Жил. площадь",
            sortName: "living_square",
            style: "width: 110px;",
          },
          {
            name: "cadastrals",
            label: "Кадастры",
            style: "width: 160px;",
          },
          {
            name: "reviewed_by",
            label: "Кто проверил",
            style: "width: 160px;",
          },
          {
            name: "reviewed_at",
            label: "Когда проверил",
            style: "width: 160px;",
          },
          {
            name: "reviewed_comment",
            label: "Комментарий",
            style: "width: 160px;",
          },
          {
            name: "actions",
            label: "",
            style: "width: 180px;",
          },
        ],
        search: {
          region_name: null,
          project: null,
          builder: null,
          developer: null,
          floor_max: { c: "eq", v: null },
          flat_qty: { c: "eq", v: null },
          parking_qty: { c: "eq", v: null },
          date_rve: {
            from: null,
            to: null,
          },
          ceiling_height: null,
          finishing_type: null,
          living_square: { c: "eq", v: null },
          cadastrals: null,
          builder_compared: null,
          developer_compared: null,
          cadastrals_compared: null,
          living_square_compared: null,
          finishing_type_compared: null,
          date_rve_compared: null,
          ceiling_height_compared: null,
          parking_qty_compared: null,
          flat_qty_compared: null,
          floor_max_compared: null,
          serial: { c: "ctn", v: null },
          reviewed_by: null,
          reviewed_at: {
            from: null,
            to: null,
          },
          reviewed_comment: { c: "ctn", v: null },
          reviewed: null,
        },
        cellStyles: {},
      };
    },

    computed: {
      reviewedFilterOptions() {
        return [
          {
            label: "Да",
            value: "yes",
          },
          {
            label: "Нет",
            value: "no",
          },
        ];
      },

      tooltipBinds() {
        return {
          delay: 800,
          "transition-show": "",
          "transition-hide": "",
        };
      },
    },

    created() {
      if (this.$route.query.page) {
        this.pagination.page = Number(this.$route.query.page);
      }
    },

    mounted() {
      this.updateColumnStyles(this.columns);
    },

    methods: {
      async filterCompounds() {
        await this.setCompounds(true);
      },

      exportExcel() {
        this.$q
          .dialog({
            title: "Выгрузка сравнений",
            message: "Название выгрузки",
            prompt: {
              model:
                "Выгрузка сравнений от " + getFormattedLocalDateTimeString(),
              type: "text",
            },
            cancel: true,
            persistent: true,
          })
          .onOk(async (data) => {
            let params = {
              q: normalizeQueryForRequest(this.search),
              sort_by: this.pagination.sortBy,
              descending: this.pagination.descending,
              page: 1,
              include:
                "box_blend.boxes.pd_files,ap_box_blend.ap_boxes,compound_static.reviewer",
              columns: [
                {
                  name: "serials",
                  label: "Номера",
                  style: "width: 120px;",
                },
                {
                  name: "region_name",
                  label: "Регион",
                  sortName: "extra.region_name",
                  style: "width: 160px;",
                },
                {
                  name: "project",
                  label: "Проект",
                  sortName: "extra.project",
                  style: "width: 160px;",
                },
                {
                  name: "builder",
                  label: "Застройщик",
                  sortName: "builder",
                  style: "width: 200px;",
                },
                {
                  name: "developer",
                  label: "Девелопер",
                  sortName: "developer",
                  style: "width: 200px;",
                },
                {
                  name: "floor_max",
                  label: "Этажность",
                  sortName: "floor_max",
                  style: "width: 100px;",
                },
                {
                  name: "flat_qty",
                  label: "Квартир",
                  sortName: "flat_qty",
                  style: "width: 100px;",
                },
                {
                  name: "parking_qty",
                  label: "Машиномест",
                  sortName: "parking_qty",
                  style: "width: 110px;",
                },
                {
                  name: "date_rve",
                  label: "РВЭ",
                  sortName: "date_rve",
                  style: "width: 160px;",
                },
                {
                  name: "ceiling_height",
                  label: "Высота потолков",
                  sortName: "ceiling_height",
                  style: "width: 120px;",
                },
                {
                  name: "finishing_type",
                  label: "Отделка",
                  sortName: "finishing_type",
                  style: "width: 160px;",
                },
                {
                  name: "living_square",
                  label: "Жил. площадь",
                  sortName: "living_square",
                  style: "width: 110px;",
                },
                {
                  name: "cadastrals",
                  label: "Кадастры",
                  style: "width: 160px;",
                },
                {
                  name: "reviewed_by",
                  label: "Кто проверил",
                  style: "width: 160px;",
                },
                {
                  name: "reviewed_at",
                  label: "Когда проверил",
                  style: "width: 160px;",
                },
                {
                  name: "reviewed_comment",
                  label: "Комментарий",
                  style: "width: 160px;",
                },
              ],
              title: data,
              rows_qty: this.pagination.rowsNumber,
            };

            this.awaitExportExcel = true;

            api.compound
              .exportExcel(params)
              .then((res) => {
                if (res.status === 201) {
                  let enc = new TextDecoder("utf-8");

                  this.$q.notify({
                    color: "positive",
                    message: JSON.parse(enc.decode(res.data)).message,
                  });
                } else {
                  const fileName = res.headers["content-disposition"].slice(21);

                  let blob = new Blob([res.data], {
                    type: res.headers["content-type"],
                  });

                  exportFile(fileName, blob);
                }
              })
              .then(() => {
                this.awaitExportExcel = false;
              });
          });
      },

      async setCompounds(isFiltering = false) {
        this.loading = true;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        let include = [
          "box_blend.boxes.pd_files",
          "ap_box_blend.ap_boxes",
          "compound_static.reviewer",
        ];

        const res = await api.compound.find(paginateOptions, include.join(","));

        if (res.data.compounds) {
          this.compounds = res.data.compounds;
          this.pagination.rowsNumber = res.data.meta.pagination.total;
        } else {
          this.compounds = [];
        }

        this.loading = false;
      },

      sortByColumn(field) {
        this.pagination.sortBy = field;
        this.pagination.descending = !this.pagination.descending;

        this.setCompounds();
      },

      async onPaginationInput(page) {
        await this.$router.replace({ query: { page: page } });
        await this.setCompounds();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      getCellClass(field, compound) {
        if (!(compound.compared && compound.compared[field])) {
          return "";
        }

        switch (compound.compared[field].status) {
          case 9:
            return "l-table__cell--negative";
          case 6:
            return "l-table__cell--warning";
          default:
            return "";
        }
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },

      toggleExpandedRow(id) {
        if (this.expandedRows.includes(id)) {
          let index = this.expandedRows.indexOf(id);
          this.expandedRows.splice(index, 1);
        } else {
          this.expandedRows.push(id);
        }
      },

      showCompareTooltip(compound, field) {
        return (
          compound.compared &&
          compound.compared[field] &&
          compound.compared[field].status > 3
        );
      },

      getSerialField(compound) {
        const boxSerials = compound.box_blend.boxes.map((i) => i.serial);
        const apBoxSerials = compound.ap_box_blend.ap_boxes.map(
          (i) => i.serial
        );

        return boxSerials.concat(apBoxSerials).join(", ");
      },

      showUpdateCompoundDialog(compound) {
        this.$q.dialog({
          component: UpdateCompoundDialog,
          componentProps: {
            compoundInitial: compound,
          },
        });
      },

      async reviewCompound(id) {
        const res = await api.compound.review(id);

        if (res.status === 200) {
          await this.setCompounds();
        }
      },

      shortness(value, length) {
        return shortness(value, length);
      },

      getStylesForCompareTable() {
        if (this.$q.screen.xs || this.$q.screen.sm) {
          return "";
        }

        const height =
          this.$store.state.windowInnerHeight - (this.$q.screen.md ? 245 : 210);

        return `max-height: ${height}px`;
      },

      getCeilingHeight(value) {
        return formatCeilingHeightArray(value);
      },
    },
  };
</script>
