import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";

const cadastral = {
  find(params, include = "") {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/cadastrals", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        include: include,
      },
    });
  },

  findReport(params, include = "") {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/cadastrals/report", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        include: include,
        reportId: params.reportId,
      },
    });
  },

  getOne(params) {
    return axios.get("/cadastrals", {
      params: {
        q: params.q,
      },
    });
  },

  create(payload) {
    return axios.post("/cadastrals", payload);
  },

  createReport(payload) {
    return axios.post("/cadastrals/report", payload);
  },

  getReportsList() {
    return axios.get("/cadastrals/reports-list");
  },

  getRegionsList() {
    return axios.get("/cadastrals/regions-list");
  },

  update(id, payload) {
    return axios.patch(`/cadastrals/${id}`, payload);
  },
  updateStatementStatus(payload) {
    return axios.patch(`/cadastrals/update-statement-status`, payload);
  },
  delete(id) {
    return axios.delete(`/cadastrals/${id}`);
  },

  deleteReport(id) {
    return axios.delete(`/cadastrals/report/${id}`);
  },

  runRosreestrDataPulling(ids) {
    return axios.get("/cadastrals/rosreestr/pull", {
      params: {
        ids: ids,
      },
    });
  },

  checkRosreestrRuAuth() {
    return axios.get("/cadastrals/rosreestr/check-rosreestr-ru-auth");
  },

  setRosreestrRuCookie(cookie) {
    return axios.post("/cadastrals/rosreestr/set-rosreestr-ru-cookie", cookie);
  },

  exportExcel(params) {
    deleteKeysWithEmptyValues(params.q);

    return axios.post(
      "/cadastrals/export/excel",
      {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        include: params.include,
        columns: params.columns,
        title: params.title,
        rows_qty: params.rows_qty,
        report_id: params.report_id,
      },
      {
        responseType: "arraybuffer",
      }
    );
  },
};

export default cadastral;
