<template>
  <div class="col bg-white rounded-borders shadow-2">
    <div class="row q-px-md q-py-sm">
      <div class="col-24 flex justify-between items-center">
        <div
          class="flex"
          :class="$q.screen.xs || $q.screen.sm ? '' : 'justify-end'"
        >
          <router-link :to="{ name: 'CadastralEdit' }" target="_blank">
            <q-btn
              icon="mdi-plus"
              label="Добавить"
              color="white"
              text-color="black"
            />
          </router-link>

          <q-btn icon="mdi-file-chart" label="Отчеты">
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <div v-for="report in reports">
                      <router-link
                        :to="{
                          path: '/cadastrals',
                          query: { report: report.id },
                        }"
                        target="_blank"
                      >
                        <q-btn icon="mdi-file" :label="report.name" />
                      </router-link>
                      <q-btn
                        flat
                        icon="mdi-delete-outline"
                        title="Удалить"
                        @click="deleteCadastralReport(report.id)"
                      />
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <q-btn icon="mdi-file-chart" label="Сохранить отчет" v-if="!isReport">
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <q-input
                      v-model="reportName"
                      autofocus
                      bottom-slots
                      clearable
                      dense
                      label="Название отчета"
                    ></q-input>

                    <div class="flex justify-between items-center">
                      <div>
                        {{ reportDateFrom || "от" }}
                      </div>
                      <div>
                        <q-icon
                          v-if="reportDateFrom"
                          class="cursor-pointer"
                          color="grey-7"
                          name="mdi-close-circle"
                          size="sm"
                          @click="reportDateFrom = null"
                        />

                        <q-icon
                          class="cursor-pointer"
                          color="grey-7"
                          name="mdi-calendar"
                          size="sm"
                        >
                          <q-popup-proxy ref="datePickerUpdatedFrom">
                            <q-date
                              v-model="reportDateFrom"
                              mask="YYYY-MM"
                              @mouseleave="$refs.datePickerUpdatedFrom.hide()"
                            />
                          </q-popup-proxy>
                        </q-icon>
                      </div>
                    </div>

                    <div class="flex justify-between items-center">
                      <div>
                        {{ reportDateTo || "до" }}
                      </div>
                      <div>
                        <q-icon
                          v-if="reportDateTo"
                          class="cursor-pointer"
                          color="grey-7"
                          name="mdi-close-circle"
                          size="sm"
                          @click="reportDateTo = null"
                        />

                        <q-icon
                          class="cursor-pointer"
                          color="grey-7"
                          name="mdi-calendar"
                          size="sm"
                        >
                          <q-popup-proxy ref="datePickerUpdatedTo">
                            <q-date
                              v-model="reportDateTo"
                              mask="YYYY-MM"
                              @mouseleave="$refs.datePickerUpdatedTo.hide()"
                            />
                          </q-popup-proxy>
                        </q-icon>
                      </div>
                    </div>

                    <q-btn
                      icon="mdi-plus"
                      label="Сохранить отчет"
                      @click="createReport"
                    />
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <q-btn
            :disable="awaitExportExcel || !cadastrals.length"
            :loading="awaitExportExcel"
            icon="mdi-file-download-outline"
            :label="$q.screen.xs || $q.screen.sm ? 'XLS' : 'Excel'"
            @click="exportExcel"
          />

          <q-btn
            icon="mdi-cog"
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Параметры'"
          >
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <q-select
                      v-model="visibleColumns"
                      :options="columns"
                      borderless
                      dense
                      display-value="Показывать столбцы"
                      emit-value
                      map-options
                      multiple
                      option-value="name"
                      options-dense
                      @update:model-value="onInputVisibleColumns"
                    >
                      <template v-slot:before>
                        <q-icon class="q-mr-sm" name="mdi-eye-check" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps">
                          <q-item-section>
                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-icon v-if="scope.selected" name="mdi-check" />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>

        <div class="flex justify-end">
          <q-btn icon="mdi-briefcase-check-outline" label="Применить статус ЗВ">
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <div>
                      <a
                        @click="selectAllCadastrals"
                        style="color: #1f9773; text-decoration: underline"
                        >Выбрать все</a
                      >
                    </div>
                    <q-select
                      v-model="rosreestrStatementStatus.forApplying"
                      @update:model-value="
                        onInputRosreestrStatementStatusForApplying
                      "
                      new-value-mode="add-unique"
                      emit-value
                      map-options
                      use-input
                      label="Выберите статус"
                      dense
                      :options="statement_statuses"
                    />
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <q-btn
            icon="mdi-briefcase-search"
            label="Росреестр"
            @click="rosreestrCheckAuth()"
          >
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <div>
                      <div
                        v-if="settings.rosreestr_data_source === 'Rosreestr.ru'"
                      >
                        <div v-if="rosreestrRuAuth === true">
                          <q-badge color="green">
                            Авторизация в Росреестре имеется
                          </q-badge>
                        </div>
                        <div v-if="rosreestrRuAuth === false">
                          <q-badge color="red">
                            Необходимо авторизоваться и добавить куки
                          </q-badge>
                          <q-input
                            label="Вставьте сюда строку Cookie"
                            v-model="rosreestrRuCookie"
                          ></q-input>
                          <q-btn @click="setRosreestrRuCookie()"
                            >Отправить куки</q-btn
                          >
                        </div>
                      </div>
                      <q-badge
                        outline
                        color="primary"
                        label="Проверок за месяц:"
                      >
                        {{
                          $store.state.queuesWorkload.rosreestr_state
                            .count_in_current_month
                        }}
                      </q-badge>
                    </div>
                    <div
                      v-if="
                        $store.state.queuesWorkload.rosreestr_state
                          .pull_job_executing
                      "
                    >
                      <q-badge
                        outline
                        color="primary"
                        label="Статус выполнения: В процессе"
                      ></q-badge>
                      <q-spinner color="primary" size="3em" />
                    </div>
                    <div v-else>
                      <q-badge
                        outline
                        color="primary"
                        label="Статус выполнения: Не активен"
                      ></q-badge>
                    </div>
                    <br />
                    <div>
                      <a
                        @click="selectAllCadastrals"
                        style="color: #1f9773; text-decoration: underline"
                        >Выбрать все</a
                      >
                    </div>

                    <q-select
                      v-model="rosreestrStatementStatus.forPullingData"
                      @update:model-value="
                        onInputRosreestrStatementStatusForPullingData
                      "
                      new-value-mode="add-unique"
                      emit-value
                      map-options
                      use-chips
                      use-input
                      label="Выбрать по статусу"
                      dense
                      multiple
                      :options="statement_statuses"
                    />

                    <q-btn
                      icon="mdi-alpha-r"
                      label="Запустить проверку"
                      :disable="
                        $store.state.queuesWorkload.rosreestr_state
                          .pull_job_executing ||
                        (settings.rosreestr_data_source === 'Rosreestr.ru' &&
                          rosreestrRuAuth !== true)
                      "
                      @click="runRosreestrDataPulling()"
                    ></q-btn>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </div>

    <div style="height: 2px">
      <q-linear-progress v-show="loading" indeterminate size="2px" />
    </div>

    <div class="row bg-white">
      <div class="col-24 q-px-md q-pt-md">
        <a v-if="isReport" href="/cadastrals">Отменить просмотр отчета</a>

        <div
          aria-label="Кадастры"
          class="l-table l-table--sticky-first-column q-pb-sm"
          role="table"
          :style="getStylesForTable()"
        >
          <div
            class="l-table__row min-w-full sticky-top bg-white"
            role="rowgroup"
            style="z-index: 1"
          >
            <div v-if="isReport">
              <div
                class="l-table__cell"
                role="columnheader"
                style="width: 58px; max-width: 58px"
              >
                <div class="text-body3">Период</div>
              </div>
            </div>
            <template v-for="item in columns" :key="item.name">
              <div
                :style="getCellStyle(item.name)"
                class="l-table__cell"
                role="columnheader"
                v-if="visibleColumns.includes(item.name)"
              >
                <div
                  :class="item.sortName && 'cursor-pointer'"
                  class="text-body3"
                  @click="item.sortName && sortByColumn(item.sortName)"
                >
                  {{ item.label }}
                  <q-icon
                    v-if="pagination.sortBy === item.sortName"
                    :name="
                      pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
                    "
                  />
                </div>
              </div>
            </template>
          </div>

          <CadastralsTableFiltersRow
            :cellStyles="cellStyles"
            :search="search"
            :visibleColumns="visibleColumns"
            @filter-cadastrals="filterCadastrals"
            @set-cadastrals="setCadastrals"
            v-if="!isReport"
          />

          <div
            v-for="cadastral in cadastrals"
            :key="cadastral.id"
            class="l-table__row"
            :class="setRowColor(cadastral)"
            role="rowgroup"
          >
            <q-menu context-menu transition-hide="" transition-show="">
              <q-list>
                <q-item clickable>
                  <q-item-section>
                    <q-select
                      v-model="cadastral.statement_status"
                      label="Статус заказа выписок"
                      :options="statement_statuses"
                      emit-value
                      map-options
                      new-value-mode="add-unique"
                      use-input
                    />

                    <q-btn
                      flat
                      icon="mdi-content-save"
                      title="Сохранить"
                      @click="
                        updateStatus(cadastral.id, cadastral.statement_status)
                      "
                      >Сохранить
                    </q-btn>
                  </q-item-section>
                </q-item>

                <q-separator />

                <q-separator />
              </q-list>
            </q-menu>

            <div
              v-if="isReport"
              :style="getCellStyle('period')"
              class="l-table__cell"
            >
              {{ cadastral.period }}
            </div>

            <div
              v-if="visibleColumns.includes('id')"
              :style="getCellStyle('id')"
              class="l-table__cell"
            >
              <router-link
                :to="{
                  path: '/cadastrals/edit',
                  query: { id: cadastral.id },
                }"
                target="_blank"
              >
                <span
                  style="
                    text-decoration: underline;
                    cursor: pointer;
                    color: black;
                  "
                  >{{ cadastral.id }}</span
                >
              </router-link>
            </div>

            <div
              v-if="visibleColumns.includes('cadastral')"
              :style="getCellStyle('cadastral')"
              class="l-table__cell"
            >
              {{ cadastral.cadastral }}
            </div>

            <div
              v-if="visibleColumns.includes('region_name')"
              :style="getCellStyle('region_name')"
              class="l-table__cell"
            >
              {{ cadastral.region_name }}
            </div>

            <div
              v-if="visibleColumns.includes('projects')"
              :style="getCellStyle('projects')"
              class="l-table__cell"
            >
              <div v-if="cadastral.projects !== null">
                {{ cadastral.projects.join(", ") }}
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('addresses')"
              :style="getCellStyle('addresses')"
              class="l-table__cell"
            >
              <div v-if="cadastral.addresses !== null">
                <div v-if="cadastral.addresses.length">
                  {{ cadastral.addresses.join(", ") }}
                </div>
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('ap_id')"
              :style="getCellStyle('ap_id')"
              class="l-table__cell"
            >
              <div v-if="cadastral.ap_id !== null">
                {{ cadastral.ap_id.join(", ") }}
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('domrf_id')"
              :style="getCellStyle('domrf_id')"
              class="l-table__cell"
            >
              <div v-if="cadastral.domrf_id !== null">
                {{ cadastral.domrf_id.join(", ") }}
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('ap_objectclass_id')"
              :style="getCellStyle('ap_objectclass_id')"
              class="l-table__cell"
            >
              <div v-if="cadastral.ap_objectclass_id !== null">
                {{ cadastral.ap_objectclass_id.join(", ") }}
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('ap_stage_id')"
              :style="getCellStyle('ap_stage_id')"
              class="l-table__cell"
            >
              <div v-if="cadastral.ap_stage_id !== null">
                {{ cadastral.ap_stage_id.join(", ") }}
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('ap_date_state_commission')"
              :style="getCellStyle('ap_date_state_commission')"
              class="l-table__cell"
            >
              <div v-if="cadastral.ap_date_state_commission !== null">
                {{ cadastral.ap_date_state_commission.join(", ") }}
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('ap_agreement_id')"
              :style="getCellStyle('ap_agreement_id')"
              class="l-table__cell"
            >
              <div v-if="cadastral.ap_agreement_id !== null">
                {{ cadastral.ap_agreement_id.join(", ") }}
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('note')"
              :style="getCellStyle('note')"
              class="l-table__cell"
            >
              {{ cadastral.note }}
            </div>
            <div
              v-if="visibleColumns.includes('statement_status')"
              :style="getCellStyle('statement_status')"
              class="l-table__cell"
            >
              <div
                v-if="cadastral.statement_status === 'Новый'"
                style="background-color: #a0dba3"
              >
                {{ cadastral.statement_status }}
              </div>
              <div v-else>
                <div
                  v-if="
                    cadastral.statement_status === 'Архив' ||
                    cadastral.statement_status === 'Не активен'
                  "
                  style="background-color: rgb(229, 229, 229)"
                >
                  {{ cadastral.statement_status }}
                </div>
                <div v-else>
                  {{ cadastral.statement_status }}
                </div>
              </div>
            </div>
            <div
              v-if="visibleColumns.includes('rosreestr_status')"
              :style="getCellStyle('rosreestr_status')"
              class="l-table__cell"
            >
              {{ cadastral.rosreestr_status }}
            </div>

            <div
              v-if="visibleColumns.includes('ap_document_date')"
              :style="getCellStyle('ap_document_date')"
              class="l-table__cell"
            >
              {{ cadastral.ap_document_date }}
            </div>
            <div
              v-if="visibleColumns.includes('ap_document_count')"
              :style="getCellStyle('ap_document_count')"
              class="l-table__cell"
            >
              {{ cadastral.ap_document_count }}
            </div>
            <div
              v-if="visibleColumns.includes('ap_registration_date')"
              :style="getCellStyle('ap_registration_date')"
              class="l-table__cell"
            >
              {{ cadastral.ap_registration_date }}
            </div>
            <div
              v-if="visibleColumns.includes('created_at')"
              :style="getCellStyle('created_at')"
              class="l-table__cell"
            >
              {{ cadastral.created_at }}
            </div>
            <div
              v-if="visibleColumns.includes('updated_at')"
              :style="getCellStyle('updated_at')"
              class="l-table__cell"
            >
              {{ cadastral.updated_at }}
            </div>
            <div
              v-if="visibleColumns.includes('user_id')"
              :style="getCellStyle('user_id')"
              class="l-table__cell"
            >
              {{ cadastral.user_id }}
            </div>

            <div
              v-if="visibleColumns.includes('last_deal_date')"
              :style="getCellStyle('last_deal_date')"
              class="l-table__cell"
            >
              {{ cadastral.last_deal_date }}
            </div>

            <div
              v-if="visibleColumns.includes('current_month_deals_count')"
              :style="getCellStyle('current_month_deals_count')"
              class="l-table__cell"
            >
              {{ cadastral.current_month_deals_count }}
            </div>

            <div
              v-if="visibleColumns.includes('current_month_deals')"
              :style="getCellStyle('current_month_deals')"
              class="l-table__cell"
            >
              <span v-if="cadastral.current_month_deals === true">Да</span>
              <span v-if="cadastral.current_month_deals === false">Нет</span>
            </div>

            <div
              v-if="visibleColumns.includes('prevent_month_deals_count')"
              :style="getCellStyle('prevent_month_deals_count')"
              class="l-table__cell"
            >
              {{ cadastral.prevent_month_deals_count }}
            </div>

            <div
              v-if="visibleColumns.includes('three_months_deals_count')"
              :style="getCellStyle('three_months_deals_count')"
              class="l-table__cell"
            >
              {{ cadastral.three_months_deals_count }}
            </div>

            <div
              v-if="visibleColumns.includes('rosreestr_response_status')"
              :style="getCellStyle('rosreestr_response_status')"
              class="l-table__cell"
            >
              {{ cadastral.rosreestr_response_status }}
            </div>

            <div
              v-if="visibleColumns.includes('rosreestr_updated_at')"
              :style="getCellStyle('rosreestr_updated_at')"
              class="l-table__cell"
            >
              {{ cadastral.rosreestr_updated_at }}
            </div>

            <div
              v-if="visibleColumns.includes('actions')"
              :style="getCellStyle('actions')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="actions"
            >
              <div class="flex no-wrap">
                <q-btn
                  :disable="awaitDeleting"
                  flat
                  icon="mdi-delete-outline"
                  title="Удалить кадастровый номер"
                  @click="deleteCadastral(cadastral.id)"
                />

                <router-link
                  :to="{
                    path: '/cadastrals/edit',
                    query: { id: cadastral.id },
                  }"
                  target="_blank"
                >
                  <q-btn
                    flat
                    color="black"
                    icon="mdi-pencil"
                    title="Открыть кадастровый номер"
                    @click="cadastralOpen(cadastral.id)"
                    :style="getButtonOpenStyle(cadastral.id)"
                  />
                </router-link>

                <q-checkbox v-model="selectedCadastrals[cadastral.id]" />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            !cadastrals ||
            (Array.isArray(cadastrals) && cadastrals.length === 0)
          "
          class="q-pa-md"
        >
          Нет данных
        </div>
      </div>

      <div
        v-if="cadastrals && cadastrals.length"
        class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white sticky-bottom shadow-up-3"
      >
        <div class="text-body3 q-mr-md">
          Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
        </div>

        <div class="row no-wrap items-center" style="height: 44px">
          <q-select
            v-model="pagination.rowsPerPage"
            :disable="loading"
            :options="[10, 30, 50]"
            borderless
            @update:model-value="onRowsPerPageInput"
          >
            <template v-slot:before>
              <q-icon name="mdi-eye-outline" size="sm" />
            </template>
          </q-select>
        </div>

        <q-pagination
          v-model="pagination.page"
          :disable="loading"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          @update:model-value="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import CadastralsTableFiltersRow from "@/components/cadastrals/CadastralsTableFiltersRow";
  import { normalizeQueryForRequest } from "@/utils/batch";
  import api from "@/api";
  import lf from "@/plugins/localforage";
  import { createMetaMixin, exportFile } from "quasar";
  import {
    deleteKeysWithEmptyValues,
    getFormattedLocalDateTimeString,
    uniencode,
    unidecode,
  } from "../../utils/batch";
  import { useRoute } from "vue-router";
  import store from "@/store";

  export default {
    name: "Cadastrals",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "База Кадастров",
        };
      }),
    ],

    components: {
      CadastralsTableFiltersRow,
    },

    data() {
      return {
        loading: false,
        settings: [],
        cadastrals: [],
        statement_statuses: [
          { label: "Активный", value: "Активный" },
          { label: "Новый", value: "Новый" },
          { label: "Не активен", value: "Не активен" },
          { label: "Архив", value: "Архив" },
          { label: "Пустой", value: "Пустой" },
        ],
        pagination: {
          rowsNumber: null,
          rowsPerPage: 30,
          sortBy: "id",
          descending: false,
          page: 1,
        },
        awaitDeleting: false,
        awaitExportExcel: false,
        isReport: false,
        reports: [],
        currentReportId: null,
        reportModel: null,
        reportName: null,
        reportDateFrom: null,
        reportDateTo: null,
        reportValue: null,

        selectedCadastrals: {},

        rosreestrRuAuth: null,
        rosreestrRuCookie: null,
        rosreestrStatementStatus: [
          {
            forPullingData: [],
            forApplying: [],
          },
        ],

        visibleColumns: [
          "id",
          "cadastral",
          "region_name",
          "projects",
          "addresses",
          "ap_date_state_commission",
          "ap_agreement_id",
          "note",
          "statement_status",
          "rosreestr_status",
          "ap_document_date",
          "ap_document_count",
          "ap_registration_date",
          "actions",
          "period",
        ],
        columns: [
          {
            name: "id",
            label: "ID",
            sortName: "id",
            style: "width: 50px;max-width: 50px;",
            visible: true,
          },
          {
            name: "cadastral",
            label: "Кадастр",
            sortName: "cadastral",
            style: "width: 120px;max-width: 120px;",
            visible: true,
          },
          {
            name: "region_name",
            label: "Регион",
            sortName: "region_name",
            style: "width: 120px;max-width: 120px;",
            visible: true,
          },
          {
            name: "projects",
            label: "Проект",
            sortName: "projects",
            style: "width: 120px;overflow: auto;max-width: 120px;",
            visible: true,
          },
          {
            name: "addresses",
            label: "Адреса",
            style: "width: 220px;overflow: auto;max-width: 120px;",
            visible: true,
          },
          {
            name: "ap_id",
            sortName: "ap_id",
            label: "ID корпусов АП",
            style: "width: 80px;max-width: 80px;overflow: auto;",
            visible: false,
          },
          {
            name: "domrf_id",
            // sortName: "domrf_id",
            label: "ID Дом.рф",
            style: "width: 80px;max-width: 80px;overflow: auto;",
            visible: false,
          },
          {
            name: "ap_objectclass_id",
            label: "Класс",
            style: "width: 100px;max-width: 100px;overflow: auto;",
            visible: false,
          },
          {
            name: "ap_stage_id",
            sortName: "ap_stage_id",
            label: "Стадия",
            style: "width: 120px;max-width: 120px;overflow: auto;",
            visible: false,
          },
          {
            name: "ap_date_state_commission",
            sortName: "ap_date_state_commission",
            label: "ГК",
            style: "width: 120px;max-width: 120px;overflow: auto;",
            visible: true,
          },
          {
            name: "ap_agreement_id",
            sortName: "ap_agreement_id",
            label: "Договор",
            style: "width: 100px;max-width: 100px;overflow: auto;",
            visible: true,
          },
          {
            name: "note",
            label: "Примечание",
            sortName: "note",
            style: "width: 100px;max-width: 100px;",
            visible: true,
          },
          {
            name: "statement_status",
            label: "Статус заказа выписок",
            sortName: "statement_status",
            style: "width: 80px;max-width: 80px;",
            visible: true,
          },
          {
            name: "rosreestr_status",
            label: "Статус Росреестра",
            sortName: "rosreestr_status",
            style: "width: 80px;max-width: 80px;",
            visible: true,
          },
          {
            name: "ap_document_date",
            label: "Дата актуальности выписки",
            sortName: "ap_document_date",
            style: "width: 100px;max-width: 80px;",
            visible: true,
          },
          {
            name: "ap_document_count",
            label: "Кол-во записей в выписке",
            sortName: "ap_document_count",
            style: "width: 70px;max-width: 70px;",
            visible: true,
          },
          {
            name: "ap_registration_date",
            label: "Дата последней регистрации сделок",
            sortName: "ap_registration_date",
            style: "width: 80px;max-width: 80px;",
            visible: true,
          },
          {
            name: "created_at",
            label: "Дата создания",
            sortName: "created_at",
            style: "width: 100px;max-width: 100px;",
            visible: false,
          },
          {
            name: "updated_at",
            label: "Дата обновления",
            sortName: "updated_at",
            style: "width: 100px;max-width: 100px;",
            visible: false,
          },
          {
            name: "user_id",
            label: "Специалист",
            sortName: "user_id",
            style: "width: 80px;max-width: 80px;",
            visible: false,
          },
          {
            name: "last_deal_date",
            label: "Дата послед. Регистрации в РР",
            sortName: "last_deal_date",
            style: "width: 80px;max-width: 80px;",
            visible: false,
          },
          {
            name: "current_month_deals_count",
            label: "Кол-во записей в РР за мес",
            sortName: "current_month_deals_count",
            style: "width: 80px;max-width: 80px;",
            visible: false,
          },
          {
            name: "current_month_deals",
            label: "Новые сделки за месяц РР",
            sortName: "current_month_deals",
            style: "width: 80px;max-width: 80px;",
            visible: false,
          },
          {
            name: "prevent_month_deals_count",
            label: "Новые сделки за прошлый месяц",
            sortName: "prevent_month_deals_count",
            style: "width: 80px;max-width: 80px;",
            visible: false,
          },
          {
            name: "three_months_deals_count",
            label: "Сделок за квартал",
            sortName: "three_months_deals_count",
            style: "width: 80px;max-width: 80px;",
            visible: false,
          },
          {
            name: "rosreestr_response_status",
            label: "Статус сбора данных в РР",
            sortName: "rosreestr_response_status",
            style: "width: 80px;max-width: 80px;",
            visible: false,
          },
          {
            name: "rosreestr_updated_at",
            label: "Дата сбора данных в РР",
            sortName: "rosreestr_updated_at",
            style: "width: 80px;max-width: 80px;",
            visible: false,
          },
          {
            name: "actions",
            label: "Действия",
            style: "width: 80px;max-width: 80px;",
            visible: true,
            order: 88,
          },
        ],
        search: {
          id: { c: "ctn", v: null },
          cadastral: { c: "ctn", v: null },
          region_name: { c: "ctn", v: null },
          projects: { c: "ctn", v: null },
          addresses: { c: "ctn", v: null },
          ap_id: { c: "ctn", v: null },
          domrf_id: { c: "ctn", v: null },
          ap_objectclass_id: { c: "ctn", v: null },
          ap_stage_id: { c: "ctn", v: null },
          ap_date_state_commission: { c: "ctn", v: null },
          ap_agreement_id: { c: "ctn", v: null },
          note: { c: "ctn", v: null },
          statement_status: { c: "ctn", v: null },
          rosreestr_status: { c: "ctn", v: null },
          ap_document_date: { from: null, to: null },
          ap_registration_date: { from: null, to: null },
          created_at: { from: null, to: null },
          updated_at: { from: null, to: null },
          ap_document_count: { c: "ctn", v: null },
          user_id: { c: "ctn", v: null },
          last_deal_date: { from: null, to: null },
          current_month_deals_count: { c: "ctn", v: null },
          current_month_deals: { c: "ctn", v: null },
          prevent_month_deals_count: { c: "ctn", v: null },
          three_months_deals_count: { c: "ctn", v: null },
          rosreestr_response_status: { c: "ctn", v: null },
          rosreestr_updated_at: { from: null, to: null },
        },
        cellStyles: {},
        cadastralButtonStyles: {},
      };
    },

    async mounted() {
      if (this.$store.state.cadastralsSearch) {
        await this.$router.replace({
          query: {
            search: this.$store.state.cadastralsSearch,
          },
        });
      }

      if (this.$route.query.search) {
        const search = unidecode(this.$route.query.search);
        Object.keys(search).forEach((key) => {
          this.search[key] = search[key];
        });
        await this.filterCadastrals();
      }

      this.updateColumnStyles(this.columns);
      await this.checkVisibleColumns();
      await this.loadRowsPerPageParamFromStorage();
      await this.setReportsList();
    },

    watch: {
      "$store.state.queuesWorkload.rosreestr_state.count_in_current_month"() {
        this.setCadastrals();
      },
    },
    methods: {
      async filterCadastrals() {
        await this.updateQuery(true);
        await this.setCadastrals(true);
      },

      async updateQuery(filtering) {
        let search = normalizeQueryForRequest(this.search);
        deleteKeysWithEmptyValues(search);
        let res = this.$store.commit(
          "SET_CADASTRALS_SEARCH",
          uniencode(search)
        );

        await this.$router.replace({
          query: {
            search: this.$store.state.cadastralsSearch,
            page: filtering ? 1 : this.pagination.page,
          },
        });
      },

      async setCadastrals(isFiltering = false) {
        if (useRoute()) {
          if (useRoute().query.hasOwnProperty("report")) {
            this.isReport = true;
            this.currentReportId = useRoute().query.report;
          }
        }

        this.loading = true;

        const settings = await api.settings.findCadastrals();
        this.settings = settings.data;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        let res;

        if (this.isReport) {
          paginateOptions.reportId = this.currentReportId;
          res = await api.cadastral.findReport(paginateOptions);
        } else {
          res = await api.cadastral.find(paginateOptions);
        }

        if (res.data.cadastrals) {
          this.cadastrals = res.data.cadastrals;
          this.pagination.rowsNumber = res.data.meta.pagination.total;
        } else {
          this.cadastrals = [];
        }

        //Setup to selectedCadastrals object a false values with key
        this.cadastrals.forEach((value) => {
          let key = value.id;
          if (!this.selectedCadastrals.hasOwnProperty(value.id)) {
            this.selectedCadastrals[key] = false;
          }
        });

        this.loading = false;
      },

      async rosreestrCheckAuth() {
        if (this.settings.rosreestr_data_source === "Rosreestr.ru") {
          let res = await api.cadastral.checkRosreestrRuAuth();
          if (res) {
            this.rosreestrRuAuth = res.data.auth;
          }
        }
      },

      async setRosreestrRuCookie() {
        await api.cadastral.setRosreestrRuCookie({
          rosreestr_ru_cookie: this.rosreestrRuCookie,
        });
        await this.rosreestrCheckAuth();
      },

      sortByColumn(field) {
        this.pagination.sortBy = field;
        this.pagination.descending = !this.pagination.descending;

        this.setCadastrals();
      },

      getStylesForTable() {
        if (this.$q.screen.xs || this.$q.screen.sm) {
          return "";
        }

        const height =
          this.$store.state.windowInnerHeight - (this.$q.screen.md ? 245 : 210);

        return `max-height: ${height}px!important`;
      },

      onPaginationInput(page) {
        this.setCadastrals();
        this.updateQuery(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },

      setRowColor(cadastral) {
        let rowClass = "";
        if (cadastral.statement_status === "Новый") {
          rowClass = "l-table__row--positive";
        }

        if (
          cadastral.statement_status === "Архив" ||
          cadastral.statement_status === "Не активен"
        ) {
          rowClass = "l-table__row--gray";
        }
        return rowClass;
      },

      cadastralOpen(id) {
        return (this.cadastralButtonStyles[id] = "background-color: #eee");
      },
      getButtonOpenStyle(id) {
        return this.cadastralButtonStyles[id];
      },
      updateStatus(id, value) {
        let payload = {
          statement_status: value,
        };
        api.cadastral
          .update(id, payload)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitDeleting = false;
          });
      },
      deleteCadastral(id) {
        this.$q
          .dialog({
            title: `Удаление кадастра ID: ${id}`,
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            this.awaitDeleting = true;

            await api.cadastral
              .delete(id)
              .then(
                (res) => {
                  this.setCadastrals();
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitDeleting = false;
              });
          });
      },

      deleteCadastralReport(id) {
        this.$q
          .dialog({
            title: `Удаление отчета ID: ${id}`,
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            this.awaitDeleting = true;

            await api.cadastral
              .deleteReport(id)
              .then(
                (res) => {
                  this.setReportsList();
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitDeleting = false;
              });
          });
      },

      async checkVisibleColumns() {
        let visibleColumns = await lf.getItem(
          "cadastrals_table_visible_columns"
        );

        if (!visibleColumns) {
          return;
        }

        const columnNames = this.columns.map((item) => item.name);

        // remove old column names
        visibleColumns.forEach((name, index) => {
          if (!columnNames.includes(name)) {
            delete visibleColumns[index];
          }
        });

        this.visibleColumns = visibleColumns;
        await this.saveVisibleColumnsInStorage(visibleColumns);
      },

      async saveVisibleColumnsInStorage(values) {
        try {
          await lf.setItem("cadastrals_table_visible_columns", values);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }
      },

      onInputVisibleColumns(values) {
        this.updateColumnStyles(this.columns);
        this.saveVisibleColumnsInStorage(values);
      },

      async loadRowsPerPageParamFromStorage() {
        let itemKey = "cadastrals_table_rows_per_page";
        if (this.isReport) {
          itemKey = "cadastrals_report_table_rows_per_page";
        }
        const rowsPerPage = await lf.getItem(itemKey);

        if (rowsPerPage && rowsPerPage !== this.pagination.rowsPerPage) {
          this.pagination.rowsPerPage = rowsPerPage;
        }
      },

      async onRowsPerPageInput(val) {
        let itemKey = "cadastrals_table_rows_per_page";
        if (this.isReport) {
          itemKey = "cadastrals_report_table_rows_per_page";
        }
        try {
          await lf.setItem(itemKey, val);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }

        await this.setCadastrals();
      },

      exportExcel() {
        this.$q
          .dialog({
            title: "Выгрузка кадастров",
            message: "Название выгрузки",
            prompt: {
              model:
                "Выгрузка кадастров от " + getFormattedLocalDateTimeString(),
              type: "text",
            },
            cancel: true,
            persistent: true,
          })
          .onOk(async (data) => {
            let params = {
              q: normalizeQueryForRequest(this.search),
              sort_by: this.pagination.sortBy,
              descending: this.pagination.descending,
              page: 1,
              include: "",
              columns: this.columns.filter((c) =>
                this.visibleColumns.includes(c.name)
              ),
              title: data,
              rows_qty: this.pagination.rowsNumber,
              report_id: this.currentReportId,
            };

            this.awaitExportExcel = true;

            api.cadastral
              .exportExcel(params)
              .then((res) => {
                if (res.status === 201) {
                  let enc = new TextDecoder("utf-8");

                  this.$q.notify({
                    color: "positive",
                    message: JSON.parse(enc.decode(res.data)).message,
                  });
                } else {
                  const fileName = res.headers["content-disposition"].slice(21);

                  let blob = new Blob([res.data], {
                    type: res.headers["content-type"],
                  });

                  exportFile(fileName, blob);
                }
              })
              .then(() => {
                this.awaitExportExcel = false;
              });
          });
      },

      createReport() {
        api.cadastral
          .createReport({
            name: this.reportName,
            value: normalizeQueryForRequest(this.search),
            dateFrom: this.reportDateFrom,
            dateTo: this.reportDateTo,
            sort_by: this.pagination.sortBy,
            descending: this.pagination.descending,
          })
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: "Отчет сохранен!",
              });
              this.setReportsList();
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          );
      },

      async setReportsList() {
        let res = await api.cadastral.getReportsList();
        if (res) {
          this.reports = res.data;
        }
      },

      selectAllCadastrals() {
        this.cadastrals.forEach((value, index) => {
          this.selectedCadastrals[value.id] = true;
        });
      },

      unselectAllCadastrals() {
        let self = this;
        Object.keys(this.selectedCadastrals).forEach(function (index) {
          self.selectedCadastrals[index] = false;
        });
      },
      getSelectedCadastralsArray() {
        let selectedCadastralsArr = [];
        let self = this;
        Object.keys(this.selectedCadastrals).forEach(function (index) {
          if (self.selectedCadastrals[index]) {
            selectedCadastralsArr.push(index);
          }
        });

        return selectedCadastralsArr;
      },

      onInputRosreestrStatementStatusForPullingData(val) {
        this.cadastrals.forEach((value) => {
          this.selectedCadastrals[value.id] = !!val.includes(
            value.statement_status
          );
        });
        this.rosreestrStatementStatus.forPullingData = [];
      },

      onInputRosreestrStatementStatusForApplying(val) {
        let selectedCadastrals = this.getSelectedCadastralsArray();

        if (selectedCadastrals.length === 0) {
          this.$q.notify({
            color: "negative",
            message: "Ээ.. Вы не выбрали ни один кадастер :((",
          });
          return;
        }
        this.$q
          .dialog({
            title: `Применить статус`,
            message:
              "Вы хотите применить статус заказа выписки к " +
              selectedCadastrals.length +
              " кадастровым номерам. Все правильно?",
            ok: {
              label: "Да",
            },
          })
          .onOk(async () => {
            let payload = {
              statement_status: val,
              ids: selectedCadastrals,
            };
            await api.cadastral.updateStatementStatus(payload).then(
              (res) => {
                this.$q.notify({
                  color: "positive",
                  message: res.data.message,
                });

                this.unselectAllCadastrals();
                this.setCadastrals();
              },
              (error) => {
                this.$q.notify({
                  color: "negative",
                  message: error.response.data.message,
                });
              }
            );
          });
      },

      async runRosreestrDataPulling() {
        let selectedCadastrals = this.getSelectedCadastralsArray();
        if (selectedCadastrals.length === 0) {
          this.$q.notify({
            color: "warning",
            message:
              "Вы не выбрали ни один кадастр, поэтому на проверку пойдут все со статусом Активный на этой странице",
          });

          this.cadastrals.forEach((value) => {
            if (value.statement_status === "Активный") {
              selectedCadastrals.push(value.id);
            }
          });
        }
        this.$q
          .dialog({
            title: `Проверка в Росреестре`,
            message:
              "Вы хотите заказать " +
              selectedCadastrals.length +
              " кадастровых номеров. Все правильно?",
            ok: {
              label: "Да",
            },
          })
          .onOk(async () => {
            await api.cadastral
              .runRosreestrDataPulling(selectedCadastrals)
              .then(
                (res) => {
                  this.$q.notify({
                    color: "positive",
                    message: "Задача поставлена в очередь",
                  });

                  this.unselectAllCadastrals();
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              );
          });
      },
    },
  };
</script>
