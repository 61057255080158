<template>
  <div v-if="!loading" class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress
        v-show="awaitDeclarationComposites"
        indeterminate
        size="2px"
      />
    </div>

    <div class="row q-px-md q-py-sm">
      <div class="col-24 flex justify-between items-center">
        <div class="flex items-center">
          <q-checkbox
            v-model="search.only_actual"
            :disable="awaitDeclarationComposites"
            label="Актуальные для АП проектов"
            @update:model-value="
              () => {
                filterDeclarationComposites();
                updateDynamicFilters();
              }
            "
          />
        </div>

        <div
          class="flex"
          :class="$q.screen.xs || $q.screen.sm ? '' : 'justify-end'"
        >
          <q-btn
            icon="mdi-sort-variant-remove"
            title="Сбросить сортировку колонок"
            @click="
              pagination.sort = {};
              setDeclarationComposites(false);
            "
          />

          <q-btn
            :disable="awaitDeclarationComposites"
            icon="mdi-refresh"
            title="Обновить данные в таблице"
            @click="setDeclarationComposites(false)"
          />

          <q-btn
            :disable="awaitExportExcel || !declarationComposites.length"
            :loading="awaitExportExcel"
            icon="mdi-file-download-outline"
            :label="$q.screen.xs || $q.screen.sm ? 'XLS' : 'Excel'"
            @click="exportExcel"
          />

          <q-btn
            icon="mdi-cog"
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Параметры'"
          >
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <q-select
                      v-model="visibleColumns"
                      :options="columnsForSelect"
                      borderless
                      dense
                      display-value="Показывать столбцы"
                      emit-value
                      map-options
                      multiple
                      option-value="name"
                      options-dense
                      @update:model-value="onInputVisibleColumns"
                    >
                      <template v-slot:before-options>
                        <div class="q-my-md q-mx-xs">
                          <q-btn
                            label="Отметить все"
                            flat
                            @click="makeAllColumnsVisible"
                          />
                          <q-btn
                            label="Стандартный набор"
                            flat
                            @click="makeDefaultColumnsVisible"
                          />
                        </div>
                      </template>

                      <template v-slot:before>
                        <q-icon class="q-mr-sm" name="mdi-eye-check" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item
                          v-if="scope.opt.name !== 'id'"
                          v-bind="scope.itemProps"
                          style="min-width: 20rem"
                        >
                          <q-item-section>
                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                            <q-item-label caption
                              >{{ scope.opt.pd_article }}
                            </q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-icon v-if="scope.selected" name="mdi-check" />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-item-section>
                </q-item>

                <q-item
                  clickable
                  @click="showDeclarationTableColumnOrderDialog"
                >
                  <q-item-section side>
                    <q-icon name="mdi-numeric" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Порядок столбцов</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </div>

    <div class="row bg-white">
      <div class="col-24 q-px-md">
        <div
          class="l-table l-table--bordered-cells l-table--striped l-table--sticky-first-column q-pb-sm"
          :style="getStylesForDeclarationCompositesTable()"
          role="table"
        >
          <div
            class="l-table__row min-w-full sticky-top bg-white"
            role="rowgroup"
            style="z-index: 2"
          >
            <template v-for="column in columns" :key="column.name">
              <div
                v-if="visibleColumns.includes(column.name)"
                :style="getCellStyle(column.name)"
                class="l-table__cell"
                role="columnheader"
              >
                <div
                  :class="column.sortName && 'cursor-pointer'"
                  class="flex items-center justify-between text-body3"
                >
                  <div
                    @click="column.sortName && sortByColumn(column.sortName)"
                  >
                    <q-icon
                      v-if="pagination.sort.hasOwnProperty(column.sortName)"
                      :name="
                        pagination.sort[column.sortName]
                          ? 'mdi-arrow-down'
                          : 'mdi-arrow-up'
                      "
                    />
                    {{ column.label }}
                  </div>
                  <q-icon
                    v-if="column.tooltip_note"
                    name="mdi-information-outline"
                    class="q-ml-xs"
                    color="primary"
                    size="xs"
                    @click="copyToBuffer(column.tooltip_note)"
                  >
                    <q-tooltip max-width="24rem">
                      {{ column.tooltip_note }}
                    </q-tooltip>
                  </q-icon>
                </div>
              </div>
            </template>
          </div>

          <DeclarationCompositeTableFiltersRow
            :cellStyles="cellStyles"
            :search="search"
            :visibleColumns="visibleColumns"
            @filter-declaration-composites="filterDeclarationComposites"
            @set-declaration-composites="setDeclarationComposites"
          />

          <div
            v-for="declarationComposite in declarationComposites"
            :key="declarationComposite.id"
            class="l-table__row min-w-full relative-position"
            :class="getDeclarationRowClasses(declarationComposite)"
            role="rowgroup"
          >
            <div
              v-if="visibleColumns.includes('actions')"
              :style="getCellStyle('actions')"
              class="l-table__cell l-table__cell--bordered"
            >
              <div class="flex no-wrap">
                <q-btn
                  v-if="+$can(['declaration-composite.delete'])"
                  :disable="awaitDeleting"
                  flat
                  icon="mdi-delete-outline"
                  title="Удалить"
                  @click="deleteDeclarationComposite(declarationComposite.id)"
                />
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('id')"
              :style="getCellStyle('id')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.id }}
            </div>

            <div
              v-if="visibleColumns.includes('dated_at')"
              :style="getCellStyle('dated_at')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.dated_at }}
            </div>

            <div
              v-if="visibleColumns.includes('ap_project_serial')"
              :style="getCellStyle('ap_project_serial')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.ap_projects &&
                declarationComposite.ap_projects.map((i) => i.serial).join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('ap_project_name')"
              :style="getCellStyle('ap_project_name')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.ap_projects &&
                declarationComposite.ap_projects.map((i) => i.name).join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('ap_project_address')"
              :style="getCellStyle('ap_project_address')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.ap_projects &&
                declarationComposite.ap_projects
                  .map((i) => i.address)
                  .join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('ap_project_region_name')"
              :style="getCellStyle('ap_project_region_name')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.ap_projects &&
                declarationComposite.ap_projects
                  .map((i) => i.region_name)
                  .join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('box_serial')"
              :style="getCellStyle('box_serial')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.box_serial &&
                declarationComposite.box_serial.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('box_mark')"
              :style="getCellStyle('box_mark')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.box_mark }}
            </div>

            <div
              v-if="visibleColumns.includes('ap_box_serial')"
              :style="getCellStyle('ap_box_serial')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.ap_box_serial &&
                declarationComposite.ap_box_serial.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('box_name')"
              :style="getCellStyle('box_name')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                shortness(
                  declarationComposite.box_name &&
                    declarationComposite.box_name.join("\n"),
                  120
                )
              }}
            </div>

            <div
              v-if="visibleColumns.includes('pd_number')"
              :style="getCellStyle('pd_number')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.pd_number &&
                declarationComposite.pd_number.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('published_at')"
              :style="getCellStyle('published_at')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.published_at &&
                declarationComposite.published_at.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('company')"
              :style="getCellStyle('company')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.company &&
                declarationComposite.company.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('builder_inn')"
              :style="getCellStyle('builder_inn')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.builder_inn &&
                declarationComposite.builder_inn.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('reported_at')"
              :style="getCellStyle('reported_at')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.reported_at }}
            </div>

            <div
              v-if="visibleColumns.includes('buildings_qty')"
              :style="getCellStyle('buildings_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.buildings_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('floors_min')"
              :style="getCellStyle('floors_min')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.floors_min }}
            </div>

            <div
              v-if="visibleColumns.includes('floors_max')"
              :style="getCellStyle('floors_max')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.floors_max }}
            </div>

            <div
              v-if="visibleColumns.includes('object_square')"
              :style="getCellStyle('object_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.object_square }}
            </div>

            <div
              v-if="visibleColumns.includes('wall_frame_material')"
              :style="getCellStyle('wall_frame_material')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.wall_frame_material &&
                declarationComposite.wall_frame_material.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('floor_material')"
              :style="getCellStyle('floor_material')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.floor_material &&
                declarationComposite.floor_material.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('living_square')"
              :style="getCellStyle('living_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_square }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_square')"
              :style="getCellStyle('nonliving_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_square }}
            </div>

            <div
              v-if="visibleColumns.includes('total_square')"
              :style="getCellStyle('total_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.total_square }}
            </div>

            <div
              v-if="visibleColumns.includes('general_contractor')"
              :style="getCellStyle('general_contractor')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.general_contractor &&
                declarationComposite.general_contractor.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('general_contractor_inn')"
              :style="getCellStyle('general_contractor_inn')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.general_contractor_inn &&
                declarationComposite.general_contractor_inn.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('construction_permit_number')"
              :style="getCellStyle('construction_permit_number')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.construction_permit_number &&
                declarationComposite.construction_permit_number.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('construction_permit_issued_at')"
              :style="getCellStyle('construction_permit_issued_at')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.construction_permit_issued_at &&
                declarationComposite.construction_permit_issued_at.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('construction_permit_valid_until')"
              :style="getCellStyle('construction_permit_valid_until')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.construction_permit_valid_until &&
                declarationComposite.construction_permit_valid_until.join("\n")
              }}
            </div>

            <div
              v-if="
                visibleColumns.includes(
                  'construction_permit_valid_until_updated_at'
                )
              "
              :style="
                getCellStyle('construction_permit_valid_until_updated_at')
              "
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.construction_permit_valid_until_updated_at &&
                declarationComposite.construction_permit_valid_until_updated_at.join(
                  "\n"
                )
              }}
            </div>

            <div
              v-if="
                visibleColumns.includes('builder_rights_ground_valid_until')
              "
              :style="getCellStyle('builder_rights_ground_valid_until')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.builder_rights_ground_valid_until &&
                declarationComposite.builder_rights_ground_valid_until.join(
                  "\n"
                )
              }}
            </div>

            <div
              v-if="
                visibleColumns.includes('builder_rights_contract_updated_at')
              "
              :style="getCellStyle('builder_rights_contract_updated_at')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.builder_rights_contract_updated_at &&
                declarationComposite.builder_rights_contract_updated_at.join(
                  "\n"
                )
              }}
            </div>

            <div
              v-if="visibleColumns.includes('cadastrals')"
              :style="getCellStyle('cadastrals')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{ getCadastrals(declarationComposite.cadastrals) }}
            </div>

            <div
              v-if="visibleColumns.includes('living_total_qty')"
              :style="getCellStyle('living_total_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_total_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_total_qty')"
              :style="getCellStyle('nonliving_total_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_total_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_total_parking_qty')"
              :style="getCellStyle('nonliving_total_parking_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_total_parking_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_total_other_qty')"
              :style="getCellStyle('nonliving_total_other_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_total_other_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('construction_stages')"
              :style="getCellStyle('construction_stages')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                getConstructionStages(declarationComposite.construction_stages)
              }}
            </div>

            <div
              v-if="visibleColumns.includes('keys_initial_issue_at')"
              :style="getCellStyle('keys_initial_issue_at')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.keys_initial_issue_at &&
                declarationComposite.keys_initial_issue_at.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('keys_planned_issue_at')"
              :style="getCellStyle('keys_planned_issue_at')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.keys_planned_issue_at &&
                declarationComposite.keys_planned_issue_at.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('builder_obligations_securing')"
              :style="getCellStyle('builder_obligations_securing')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.builder_obligations_securing &&
                declarationComposite.builder_obligations_securing.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('escrow_bank')"
              :style="getCellStyle('escrow_bank')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.escrow_bank &&
                declarationComposite.escrow_bank.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('payment_contributions_fund')"
              :style="getCellStyle('payment_contributions_fund')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.payment_contributions_fund &&
                declarationComposite.payment_contributions_fund.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('fund_bank')"
              :style="getCellStyle('fund_bank')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.fund_bank &&
                declarationComposite.fund_bank.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('builder_raising_money')"
              :style="getCellStyle('builder_raising_money')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.builder_raising_money &&
                declarationComposite.builder_raising_money.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('financing_bank')"
              :style="getCellStyle('financing_bank')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.financing_bank &&
                declarationComposite.financing_bank.join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('living_escrow_qty')"
              :style="getCellStyle('living_escrow_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_escrow_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('living_fund_qty')"
              :style="getCellStyle('living_fund_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_fund_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('living_other_qty')"
              :style="getCellStyle('living_other_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_other_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_escrow_qty')"
              :style="getCellStyle('nonliving_escrow_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_escrow_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_fund_qty')"
              :style="getCellStyle('nonliving_fund_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_fund_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_other_qty')"
              :style="getCellStyle('nonliving_other_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_other_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('parking_escrow_qty')"
              :style="getCellStyle('parking_escrow_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_escrow_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('parking_fund_qty')"
              :style="getCellStyle('parking_fund_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_fund_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('parking_other_qty')"
              :style="getCellStyle('parking_other_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_other_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('living_escrow_square')"
              :style="getCellStyle('living_escrow_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_escrow_square }}
            </div>

            <div
              v-if="visibleColumns.includes('living_fund_square')"
              :style="getCellStyle('living_fund_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_fund_square }}
            </div>

            <div
              v-if="visibleColumns.includes('living_other_square')"
              :style="getCellStyle('living_other_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_other_square }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_escrow_square')"
              :style="getCellStyle('nonliving_escrow_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_escrow_square }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_fund_square')"
              :style="getCellStyle('nonliving_fund_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_fund_square }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_other_square')"
              :style="getCellStyle('nonliving_other_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_other_square }}
            </div>

            <div
              v-if="visibleColumns.includes('parking_escrow_square')"
              :style="getCellStyle('parking_escrow_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_escrow_square }}
            </div>

            <div
              v-if="visibleColumns.includes('parking_fund_square')"
              :style="getCellStyle('parking_fund_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_fund_square }}
            </div>

            <div
              v-if="visibleColumns.includes('parking_other_square')"
              :style="getCellStyle('parking_other_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_other_square }}
            </div>

            <div
              v-if="visibleColumns.includes('living_escrow_price')"
              :style="getCellStyle('living_escrow_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.living_escrow_price) }}
            </div>

            <div
              v-if="visibleColumns.includes('living_fund_price')"
              :style="getCellStyle('living_fund_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.living_fund_price) }}
            </div>

            <div
              v-if="visibleColumns.includes('living_other_price')"
              :style="getCellStyle('living_other_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.living_other_price) }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_escrow_price')"
              :style="getCellStyle('nonliving_escrow_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.nonliving_escrow_price) }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_fund_price')"
              :style="getCellStyle('nonliving_fund_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.nonliving_fund_price) }}
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_other_price')"
              :style="getCellStyle('nonliving_other_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.nonliving_other_price) }}
            </div>

            <div
              v-if="visibleColumns.includes('parking_escrow_price')"
              :style="getCellStyle('parking_escrow_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.parking_escrow_price) }}
            </div>

            <div
              v-if="visibleColumns.includes('parking_fund_price')"
              :style="getCellStyle('parking_fund_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.parking_fund_price) }}
            </div>

            <div
              v-if="visibleColumns.includes('parking_other_price')"
              :style="getCellStyle('parking_other_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.parking_other_price) }}
            </div>

            <div
              v-if="visibleColumns.includes('lift_qty')"
              :style="getCellStyle('lift_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.lift_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('lift_cargo_qty')"
              :style="getCellStyle('lift_cargo_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.lift_cargo_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('lift_wheelchair_qty')"
              :style="getCellStyle('lift_wheelchair_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.lift_wheelchair_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('created_at')"
              :style="getCellStyle('created_at')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.created_at }}
            </div>

            <div
              v-if="visibleColumns.includes('updated_at')"
              :style="getCellStyle('updated_at')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.updated_at }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          !declarationComposites ||
          (Array.isArray(declarationComposites) &&
            declarationComposites.length === 0)
        "
        class="q-pa-md"
      >
        Нет данных
      </div>

      <div
        v-if="declarationComposites && declarationComposites.length"
        class="col-24 flex justify-end items-center q-px-md bg-white sticky-bottom shadow-up-3"
        style="z-index: 2"
      >
        <div class="text-body3 q-mr-md">
          Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
        </div>

        <q-select
          v-model="pagination.rowsPerPage"
          :disable="loading"
          :options="[10, 25, 50]"
          borderless
          @update:model-value="onRowsPerPageInput"
        >
          <template v-slot:before>
            <q-icon name="mdi-eye-outline" size="sm" />
          </template>
        </q-select>

        <q-pagination
          v-model="pagination.page"
          :disable="loading"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          :model-value="pagination.page"
          @update:model-value="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import lf from "@/plugins/localforage";
  import emitter from "@/plugins/mitt";
  import {
    customSort,
    deleteKeysWithEmptyValues,
    getFormattedLocalDateTimeString,
    normalizeQueryForRequest,
    unidecode,
    uniencode,
  } from "@/utils/batch";
  import { rubFormat, numberFormat } from "@/utils/numberFormatter";
  import { shortness } from "@/plugins/filters";
  import { markRaw } from "vue";
  import { createMetaMixin, exportFile, copyToClipboard } from "quasar";
  import DeclarationTableFiltersRow from "@/components/declarations/DeclarationTableFiltersRow";
  import DeclarationCellAlertTooltip from "@/components/declarations/DeclarationCellAlertTooltip";
  import {
    columns as decColumns,
    defaultVisibleColumns,
  } from "@/utils/declarationComposite";
  import DeclarationCompositeTableFiltersRow from "@/components/declarationComposites/DeclarationCompositeTableFiltersRow";
  import DeclarationCompositeTableColumnOrderDialog from "@/components/dialogs/DeclarationCompositeTableColumnOrderDialog";

  export default {
    name: "DeclarationCompositeList",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Агрегированные декларации",
        };
      }),
    ],

    components: {
      DeclarationCompositeTableFiltersRow,
      DeclarationCellAlertTooltip,
      DeclarationTableFiltersRow,
    },

    created() {
      emitter.on(
        "declaration-composites-change-column-order",
        this.onChangeColumnOrder
      );
    },

    async mounted() {
      if (this.$route.query.page) {
        this.pagination.page = Number(this.$route.query.page);
      }

      if (this.$store.state.declarationSearch) {
        await this.$router.replace({
          query: {
            search: this.$store.state.declarationSearch,
          },
        });
      }

      if (this.$route.query.search) {
        const search = unidecode(this.$route.query.search);

        Object.keys(search).forEach((key) => {
          this.search[key] = search[key];
        });
      }

      const pdFieldNames = this.pdFields.map((f) => f.name);

      this.columns.forEach((column, index) => {
        if (column.label) {
          return;
        }

        if (pdFieldNames.includes(column.name)) {
          let pdFieldColumn = this.pdFields.filter(
            (f) => f.name === column.name
          )[0];
          this.columns[index].data_type = pdFieldColumn.data_type;
          this.columns[index].name_db = pdFieldColumn.name;
          this.columns[index].label = pdFieldColumn.name_readable;
          this.columns[index].pd_title = pdFieldColumn.pd_title;
          this.columns[index].pd_article = pdFieldColumn.pd_article;
          this.columns[index].tooltip_note = this.getColumnTooltipText(
            this.columns[index]
          );
        }
      });

      // using map-options for q-select slows down performance for large data-sets
      const columnsForSelect = this.columns.map((c) => ({
        label: c.label,
        name: c.name,
        pd_article: c.pd_article,
      }));
      this.columnsForSelect = markRaw(columnsForSelect);

      await this.checkVisibleColumns();
      await this.syncColumnSettings();
      await this.updateColumnOrderByUserSettings();
      this.sortColumnsByOrder();
      this.updateColumnStyles(this.columns);
      await this.loadRowsPerPageParamFromStorage();

      const [resApProjects] = await Promise.all([
        api.apProject.find({
          q: {},
          transformer: "ApProjectShortTransformer",
        }),
      ]);

      this.apProjects = customSort(resApProjects.data || [], "name");
      this.apProjectsData = customSort(resApProjects.data || [], "name");

      this.loading = false;
    },

    unmounted() {
      emitter.off("declarations-change-column-order", this.onChangeColumnOrder);
    },

    computed: {
      pdFields() {
        return this.$store.state.pdFields;
      },
    },

    data() {
      return {
        loading: true,
        awaitDeleting: false,
        awaitDeclarationComposites: false,
        declarationUpdating: [],
        awaitExportExcel: false,
        declarationComposites: [],
        apProjects: [],
        pagination: {
          rowsNumber: null,
          rowsPerPage: 10,
          sort: {
            dated_at: true,
          },
          page: 1,
        },
        columnsForSelect: [],
        columns: markRaw(decColumns),
        visibleColumns: defaultVisibleColumns,
        search: {
          id: { c: "eq", v: null },
          dated_at: {
            from: null,
            to: null,
          },
          ap_project_serials: [],
          ap_project_serial: { c: "ctn", v: null },
          ap_project_name: { c: "ctn", v: null },
          ap_project_address: { c: "ctn", v: null },
          ap_project_region_name: [],
          box_serial: { c: "ctn", v: null },
          box_mark: { c: "ctn", v: null },
          ap_box_serial: { c: "ctn", v: null },
          box_name: null,
          pd_number: { c: "ctn", v: null },
          published_at: {
            from: null,
            to: null,
          },
          company: null,
          builder_inn: { c: "ctn", v: null },
          reported_at: {
            from: null,
            to: null,
          },
          buildings_qty: { c: "gte", v: null },
          floors_min: { c: "gte", v: null },
          floors_max: { c: "gte", v: null },
          object_square: { c: "gte", v: null },
          wall_frame_material: null,
          floor_material: null,
          living_square: { c: "gte", v: null },
          nonliving_square: { c: "gte", v: null },
          total_square: { c: "gte", v: null },
          general_contractor: null,
          general_contractor_inn: null,
          construction_permit_number: null,
          construction_permit_issued_at: {
            from: null,
            to: null,
          },
          construction_permit_valid_until: {
            from: null,
            to: null,
          },
          construction_permit_valid_until_updated_at: {
            from: null,
            to: null,
          },
          builder_rights_ground_valid_until: {
            from: null,
            to: null,
          },
          builder_rights_contract_updated_at: {
            from: null,
            to: null,
          },
          cadastral: null,
          living_total_qty: { c: "gte", v: null },
          nonliving_total_qty: { c: "gte", v: null },
          nonliving_total_parking_qty: { c: "gte", v: null },
          nonliving_total_other_qty: { c: "gte", v: null },
          keys_initial_issue_at: {
            from: null,
            to: null,
          },
          keys_planned_issue_at: {
            from: null,
            to: null,
          },
          builder_obligations_securing: [],
          escrow_bank: null,
          payment_contributions_fund: [],
          fund_bank: null,
          builder_raising_money: [],
          financing_bank: null,
          living_escrow_qty: { c: "gte", v: null },
          living_fund_qty: { c: "gte", v: null },
          living_other_qty: { c: "gte", v: null },
          nonliving_escrow_qty: { c: "gte", v: null },
          nonliving_fund_qty: { c: "gte", v: null },
          nonliving_other_qty: { c: "gte", v: null },
          parking_escrow_qty: { c: "gte", v: null },
          parking_fund_qty: { c: "gte", v: null },
          parking_other_qty: { c: "gte", v: null },
          living_escrow_square: { c: "gte", v: null },
          living_fund_square: { c: "gte", v: null },
          living_other_square: { c: "gte", v: null },
          nonliving_escrow_square: { c: "gte", v: null },
          nonliving_fund_square: { c: "gte", v: null },
          nonliving_other_square: { c: "gte", v: null },
          parking_escrow_square: { c: "gte", v: null },
          parking_fund_square: { c: "gte", v: null },
          parking_other_square: { c: "gte", v: null },
          living_escrow_price: { c: "gte", v: null },
          living_fund_price: { c: "gte", v: null },
          living_other_price: { c: "gte", v: null },
          nonliving_escrow_price: { c: "gte", v: null },
          nonliving_fund_price: { c: "gte", v: null },
          nonliving_other_price: { c: "gte", v: null },
          parking_escrow_price: { c: "gte", v: null },
          parking_fund_price: { c: "gte", v: null },
          parking_other_price: { c: "gte", v: null },
          lift_qty: { c: "gte", v: null },
          lift_cargo_qty: { c: "gte", v: null },
          lift_wheelchair_qty: { c: "gte", v: null },
          created_at: {
            from: null,
            to: null,
          },
          updated_at: {
            from: null,
            to: null,
          },
          only_actual: false,
        },
        cellStyles: {},
      };
    },

    methods: {
      updateDynamicFilters() {
        emitter.emit("declaration-composites-update-dynamic-filters");
      },

      async filterDeclarationComposites() {
        await this.updateQuery(true);
        await this.setDeclarationComposites(true);
      },

      async setDeclarationComposites(isFiltering = false) {
        this.awaitDeclarationComposites = true;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sort,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        const include = ["ap_projects"];

        const res = await api.declarationComposite.find(
          paginateOptions,
          include.join(",")
        );

        if (res.status === 200 && res.data.declarationcomposites) {
          this.declarationComposites = res.data.declarationcomposites;
          this.pagination.rowsNumber = res.data.meta.pagination.total;
        }

        if (res.status === 204) {
          this.declarationComposites = [];
        }

        this.awaitDeclarationComposites = false;
      },

      makeDefaultColumnsVisible() {
        this.visibleColumns = defaultVisibleColumns;
        this.onInputVisibleColumns(
          JSON.parse(JSON.stringify(this.visibleColumns))
        );
      },

      makeAllColumnsVisible() {
        this.visibleColumns = this.columnsForSelect.map((i) => i.name);
        this.onInputVisibleColumns(
          JSON.parse(JSON.stringify(this.visibleColumns))
        );
      },

      onInputVisibleColumns(values) {
        this.updateColumnStyles(this.columns);
        this.saveVisibleColumnsInStorage(values);
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style + `order:${item.order};`;
        });

        this.cellStyles = obj;
      },

      async syncColumnSettings() {
        const userColumnNames =
          (await lf.getItem("declaration_composites_column_order")) || [];
        const columnNames = this.columns.map((i) => i.name);

        if (
          userColumnNames.length !== columnNames.length ||
          columnNames.some((name) => !userColumnNames.includes(name))
        ) {
          // replace user settings by default columns order
          await lf.setItem(
            "declaration_composites_column_order",
            this.columns.map((item) => item.name)
          );
        }
      },

      async updateColumnOrderByUserSettings() {
        const userColumnNames = await lf.getItem(
          "declaration_composites_column_order"
        );

        if (!userColumnNames) {
          return;
        }

        this.columns.forEach((item, index) => {
          const foundIndex = userColumnNames.findIndex(
            (colName) => colName === item.name
          );

          if (foundIndex === -1) {
            throw new Error(
              `can not find "${item.name}" column in user's column settings`
            );
          }

          this.columns[index].order = foundIndex;
        });
      },

      async checkVisibleColumns() {
        let visibleColumns = await lf.getItem(
          "declaration_composites_table_visible_columns"
        );

        if (visibleColumns) {
          const columnNames = this.columns.map((item) => item.name);

          // remove old column names
          visibleColumns.forEach((name, index) => {
            if (!columnNames.includes(name)) {
              delete visibleColumns[index];
            }
          });

          this.visibleColumns = visibleColumns;
          await this.saveVisibleColumnsInStorage(visibleColumns);
        }
      },

      async saveVisibleColumnsInStorage(values) {
        try {
          await lf.setItem(
            "declaration_composites_table_visible_columns",
            values
          );
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }
      },

      async onPaginationInput(page) {
        // this.pagination.page = page;
        await this.updateQuery(false);
        await this.setDeclarationComposites();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      async loadRowsPerPageParamFromStorage() {
        const rowsPerPage = await lf.getItem(
          "declaration_composites_table_rows_per_page"
        );

        if (rowsPerPage && rowsPerPage !== this.pagination.rowsPerPage) {
          this.pagination.rowsPerPage = rowsPerPage;
        }
      },

      async onRowsPerPageInput(val) {
        try {
          await lf.setItem("declaration_composites_table_rows_per_page", val);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }

        await this.setDeclarationComposites();
      },

      sortByColumn(field) {
        this.pagination.sort[field] = !this.pagination.sort[field];
        this.setDeclarationComposites();
      },

      deleteDeclarationComposite(id) {
        this.$q
          .dialog({
            title: `Удаление агрегированной декларации ID: ${id}`,
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            this.awaitDeleting = true;

            await api.declarationComposite
              .delete(id)
              .then(
                (res) => {
                  this.setDeclarationComposites();
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitDeleting = false;
              });
          });
      },

      exportExcel() {
        this.$q
          .dialog({
            title: "Выгрузка агрегированных деклараций",
            message: "Название выгрузки",
            prompt: {
              model:
                "Выгрузка агрегированных деклараций от " +
                getFormattedLocalDateTimeString(),
              type: "text",
            },
            cancel: true,
            persistent: true,
          })
          .onOk(async (data) => {
            let params = {
              q: normalizeQueryForRequest(this.search),
              sort_by: this.pagination.sortBy,
              descending: this.pagination.descending,
              page: 1,
              include: "ap_projects",
              columns: this.columns.filter((c) =>
                this.visibleColumns.includes(c.name)
              ),
              title: data,
              rows_qty: this.pagination.rowsNumber,
            };

            this.awaitExportExcel = true;

            api.declarationComposite
              .exportExcel(params)
              .then((res) => {
                if (res.status === 201) {
                  let enc = new TextDecoder("utf-8");

                  this.$q.notify({
                    color: "positive",
                    message: JSON.parse(enc.decode(res.data)).message,
                  });
                } else {
                  const fileName = res.headers["content-disposition"].slice(21);

                  let blob = new Blob([res.data], {
                    type: res.headers["content-type"],
                  });

                  exportFile(fileName, blob);
                }
              })
              .then(() => {
                this.awaitExportExcel = false;
              });
          });
      },

      getColumnTooltipText(column) {
        let text = "";

        if (column.pd_article) {
          if (Array.isArray(column.pd_article)) {
            text += column.pd_article.join(", ") + ` ${column.pd_title}`;
          } else {
            text += `${column.pd_article} ${column.pd_title}`;
          }
        }

        if (column.name_db) {
          text += ` (${column.name_db})`;
        }

        return text.trim();
      },

      shortness(value, length) {
        return shortness(value, length);
      },

      getCadastrals(cadastrals) {
        if (!cadastrals) {
          return "";
        }

        const numbers = cadastrals.numbers.join("\n");

        return `Номера:\n${numbers}\nПлощадь: ${cadastrals.square} м²`;
      },

      getConstructionStages(stages) {
        if (!stages) {
          return "";
        }

        let text = "";

        stages.forEach((i) => {
          text += `${i.ending_at} ${i.percent}%\n`;
        });

        return text;
      },

      getDeclarationRowClasses(entity) {
        let classes = [];

        if (entity.is_actual) {
          classes.push("l-table__row--marked-a");
        }

        if (!entity.validation) {
          return classes.join(" ");
        }

        classes.push("l-table__row--negative");

        return classes.join(" ");
      },

      getStylesForDeclarationCompositesTable() {
        if (this.$q.screen.xs || this.$q.screen.sm) {
          return "";
        }

        const height =
          this.$store.state.windowInnerHeight - (this.$q.screen.md ? 230 : 195);

        return `max-height: ${height}px`;
      },

      numF(val) {
        return numberFormat(val);
      },

      rubF(val) {
        return rubFormat(val);
      },

      copyToBuffer(val) {
        copyToClipboard(val)
          .then(() => {
            this.$q.notify({
              color: "positive",
              message: "Скопировано в буфер!",
            });
          })
          .catch(() => {
            this.$q.notify({
              color: "negative",
              message: "Копирование в буфер не выполнено",
            });
          });
      },

      onChangeColumnOrder(payload) {
        this.sortColumnsByOrder(); // sort to show actual order in visibleColumns select
        this.updateColumnStyles(payload.columns);
      },

      sortColumnsByOrder() {
        customSort(this.columns, "order");
      },

      async updateQuery(filtering) {
        let search = normalizeQueryForRequest(this.search);
        deleteKeysWithEmptyValues(search);
        this.$store.commit(
          "SET_DECLARATION_COMPOSITE_SEARCH",
          uniencode(search)
        );

        await this.$router.replace({
          query: {
            search: this.$store.state.declarationCompositeSearch,
            page: filtering ? 1 : this.pagination.page,
          },
        });
      },

      filterApProjects(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.apProjects = this.apProjectsData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      showDeclarationTableColumnOrderDialog() {
        this.$q.dialog({
          component: DeclarationCompositeTableColumnOrderDialog,
          componentProps: {
            columns: this.columns,
          },
        });
      },
    },
  };
</script>
