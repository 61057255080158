<template>
  <q-layout v-if="$store.getters['auth/isAuth']" view="hHh lpR fFf">
    <q-header bordered class="bg-white text-grey-9" style="height: 50px">
      <q-toolbar style="height: 50px">
        <q-btn
          v-if="$q.screen.xs || $q.screen.sm"
          dense
          flat
          icon="mdi-menu"
          round
          @click="isDrawerLeftShown = !isDrawerLeftShown"
        />

        <div v-if="!($q.screen.xs || $q.screen.sm)" class="flex items-center">
          <q-btn
            v-if="+$can(['compound.view'])"
            :ripple="false"
            flat
            icon="mdi-align-vertical-bottom"
            label="Сравнение"
            no-caps
            :to="{ name: 'Compare' }"
            :class="
              $router.currentRoute.value.name === 'Compare'
                ? 'router-link-active'
                : ''
            "
          />

          <q-btn
            v-if="+$can(['ap-project.view'])"
            :ripple="false"
            flat
            icon="mdi-home-city-outline"
            label="АП проекты"
            no-caps
            :to="{ name: 'ApProjectList' }"
            :class="
              $router.currentRoute.value.name === 'ApProjectList'
                ? 'router-link-active'
                : ''
            "
          />

          <q-btn
            v-if="+$can(['ap-box.view'])"
            :ripple="false"
            flat
            icon="mdi-office-building"
            label="АП корпуса"
            no-caps
            :to="{ name: 'ApBoxList' }"
            :class="
              $router.currentRoute.value.name === 'ApBoxList'
                ? 'router-link-active'
                : ''
            "
          />

          <q-btn
            v-if="+$can(['box.view'])"
            :ripple="false"
            flat
            icon="mdi-office-building"
            label="ДомРФ корпуса"
            no-caps
            :to="{ name: 'BoxList' }"
            :class="
              $router.currentRoute.value.name === 'BoxList'
                ? 'router-link-active'
                : ''
            "
          />

          <q-btn
            v-if="+$can(['declaration.view'])"
            :ripple="false"
            flat
            icon-right="mdi-chevron-down"
            icon="mdi-file-document-outline"
            label="ПД"
            no-caps
            type="a"
            :class="
              $router.currentRoute.value.matched.length &&
              $router.currentRoute.value.matched
                .map((r) => r.path.split('/')[1])
                .includes('declarations')
                ? 'router-link-active'
                : ''
            "
          >
            <q-menu>
              <q-list>
                <q-item
                  v-if="+$can(['declaration.view'])"
                  v-close-popup
                  :to="{ name: 'DeclarationList' }"
                  clickable
                >
                  <q-item-section>
                    <q-item-label>Декларации</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item
                  v-if="+$can(['declaration-composite.view'])"
                  v-close-popup
                  :to="{ name: 'DeclarationCompositeList' }"
                  clickable
                >
                  <q-item-section>
                    <q-item-label>Агрегированные ПД</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item
                  v-if="+$can(['declaration-correction.view'])"
                  v-close-popup
                  :to="{ name: 'DeclarationCorrectionList' }"
                  clickable
                >
                  <q-item-section>
                    <q-item-label>Корректировки</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item
                  v-if="+$can(['pd-file.view'])"
                  v-close-popup
                  :to="{ name: 'PdFileList' }"
                  clickable
                >
                  <q-item-section>
                    <q-item-label>Файлы</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item
                  v-if="+$can(['settings.pd.view'])"
                  v-close-popup
                  :to="{ name: 'PdSettings' }"
                  clickable
                >
                  <q-item-section>
                    <q-item-label>Настройки</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <q-btn
            v-if="+$can(['cadastral.view'])"
            :to="{ name: 'Cadastrals' }"
            :ripple="false"
            flat
            icon="mdi-archive-outline"
            :label="$q.screen.md ? 'Кадастры' : 'База Кадастров'"
            no-caps
            :class="
              $router.currentRoute.value.name === 'Cadastrals'
                ? 'router-link-active'
                : ''
            "
          />

          <q-btn
            v-if="+$can(['log.view'])"
            :to="{ name: 'Logs' }"
            :ripple="false"
            flat
            icon="mdi-archive-outline"
            label="Логи"
            no-caps
            :class="
              $router.currentRoute.value.name === 'Logs'
                ? 'router-link-active'
                : ''
            "
          />

          <q-btn
            v-if="+$can(['settings.view'])"
            :ripple="false"
            flat
            icon="mdi-cog"
            label="Настройки"
            no-caps
            type="a"
            :class="
              $router.currentRoute.value.matched.length &&
              $router.currentRoute.value.matched
                .map((r) => r.path.split('/')[1])
                .includes('settings')
                ? 'router-link-active'
                : ''
            "
          >
            <q-menu>
              <q-list>
                <q-item
                  v-for="item in settingsItems"
                  :key="item.to"
                  v-close-popup
                  :to="item.to"
                  clickable
                >
                  <q-item-section>
                    <q-item-label>{{ item.text }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>

        <div class="flex items-center q-ml-sm">
          <q-icon
            :name="$store.state.queuesWorkload.showcase.icon"
            :color="$store.state.queuesWorkload.showcase.color"
            size="sm"
          />
          <q-tooltip :delay="500">
            <div class="flex column">
              <div class="text-bold">Queues workload</div>
              <div>High: {{ $store.state.queuesWorkload.high }}</div>
              <div>Default: {{ $store.state.queuesWorkload.default }}</div>
              <div>Low: {{ $store.state.queuesWorkload.low }}</div>
            </div>
          </q-tooltip>
        </div>

        <q-btn-dropdown
          :label="$store.getters['auth/user'].name"
          auto-close
          class="absolute-right"
          color="grey-9"
          flat
          icon="mdi-account-circle-outline"
          no-caps
        >
          <q-list>
            <q-item v-close-popup :to="{ name: 'Account' }" clickable>
              <q-item-section side>
                <q-icon name="mdi-account-circle-outline" size="1.25rem" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Аккаунт</q-item-label>
              </q-item-section>
            </q-item>

            <q-item v-close-popup :to="{ name: 'AccountFiles' }" clickable>
              <q-item-section side>
                <q-icon name="mdi-file" size="1.25rem" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Файлы</q-item-label>
              </q-item-section>
            </q-item>

            <q-item v-close-popup clickable @click="logout">
              <q-item-section side>
                <q-icon name="mdi-power" size="1.25rem" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Выйти</q-item-label>
              </q-item-section>
            </q-item>

            <q-item>
              <q-item-section class="text-body2 text-grey-7 pre-line">
                {{
                  $store.getters["auth/user"].regions
                    .map((r) => r.name)
                    .join("\n")
                }}
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-if="$q.screen.xs || $q.screen.sm"
      v-model="isDrawerLeftShown"
      :width="110"
      bordered
      side="left"
    >
      <div class="flex column items-center q-py-md q-gutter-y-md">
        <q-btn
          v-if="+$can(['compound.view'])"
          :ripple="false"
          flat
          icon="mdi-align-vertical-bottom"
          label="Сравнение"
          no-caps
          stack
          :to="{ name: 'Compare' }"
          :class="
            $router.currentRoute.value.name === 'Compare'
              ? 'router-link-active'
              : ''
          "
        />

        <q-btn
          v-if="+$can(['ap-project.view'])"
          :ripple="false"
          flat
          icon="mdi-home-city-outline"
          label="АП проекты"
          no-caps
          stack
          :to="{ name: 'ApProjectList' }"
          :class="
            $router.currentRoute.value.name === 'ApProjectList'
              ? 'router-link-active'
              : ''
          "
        />

        <q-btn
          v-if="+$can(['ap-box.view'])"
          :ripple="false"
          flat
          icon="mdi-office-building"
          label="АП корпуса"
          no-caps
          stack
          :to="{ name: 'ApBoxList' }"
          :class="
            $router.currentRoute.value.name === 'ApBoxList'
              ? 'router-link-active'
              : ''
          "
        />

        <q-btn
          v-if="+$can(['box.view'])"
          :ripple="false"
          flat
          icon="mdi-office-building"
          label="ДомРФ корпуса"
          no-caps
          stack
          :to="{ name: 'BoxList' }"
          :class="
            $router.currentRoute.value.name === 'BoxList'
              ? 'router-link-active'
              : ''
          "
        />

        <q-btn
          v-if="+$can(['declaration.view'])"
          :ripple="false"
          flat
          icon="mdi-data-matrix"
          label="ПД"
          stack
          no-caps
          :class="
            $router.currentRoute.value.matched.length &&
            $router.currentRoute.value.matched
              .map((r) => r.path.split('/')[1])
              .includes('declarations')
              ? 'router-link-active'
              : ''
          "
        >
          <q-menu>
            <q-list>
              <q-item
                v-if="+$can(['declaration.view'])"
                v-close-popup
                :to="{ name: 'DeclarationList' }"
                clickable
              >
                <q-item-section>
                  <q-item-label>Декларации</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-if="+$can(['declaration-composite.view'])"
                v-close-popup
                :to="{ name: 'DeclarationCompositeList' }"
                clickable
              >
                <q-item-section>
                  <q-item-label>Агрегированные ПД</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-if="+$can(['declaration-correction.view'])"
                v-close-popup
                :to="{ name: 'DeclarationCorrectionList' }"
                clickable
              >
                <q-item-section>
                  <q-item-label>Корректировки</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-if="+$can(['pd-file.view'])"
                v-close-popup
                :to="{ name: 'PdFileList' }"
                clickable
              >
                <q-item-section>
                  <q-item-label>Файлы</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-if="+$can(['settings.pd.view'])"
                v-close-popup
                :to="{ name: 'PdSettings' }"
                clickable
              >
                <q-item-section>
                  <q-item-label>Настройки</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>

        <q-btn
          v-if="+$can(['log.view'])"
          :to="{ name: 'Logs' }"
          :ripple="false"
          flat
          icon="mdi-archive-outline"
          label="Логи"
          no-caps
          stack
          :class="
            $router.currentRoute.value.name === 'Logs'
              ? 'router-link-active'
              : ''
          "
        />

        <q-btn
          v-if="+$can(['settings.view'])"
          :ripple="false"
          flat
          icon="mdi-cog"
          label="Настройки"
          no-caps
          stack
          :class="
            $router.currentRoute.value.matched.length &&
            $router.currentRoute.value.matched
              .map((r) => r.path.split('/')[1])
              .includes('settings')
              ? 'router-link-active'
              : ''
          "
        >
          <q-menu>
            <q-list>
              <q-item
                v-for="item in settingsItems"
                :key="item.to"
                v-close-popup
                :to="item.to"
                clickable
              >
                <q-item-section>
                  <q-item-label>{{ item.text }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </q-drawer>

    <q-page-container>
      <q-page>
        <Breadcrumbs
          v-if="
            !($q.screen.xs || $q.screen.sm) &&
            !disableBreadcrumbsForRoutes.includes($route.name)
          "
        />
        <router-view />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
  import { actions as authActions } from "@/store/auth/actions";
  import Breadcrumbs from "@/components/Breadcrumbs";

  export default {
    name: "MainLayout",

    components: {
      Breadcrumbs,
    },

    data() {
      return {
        isDrawerLeftShown: true,
      };
    },

    computed: {
      settingsItems() {
        let items = [
          {
            to: { name: "RegionList" },
            text: "Регионы",
            visible: +this.$can(["region.update"]),
          },
          {
            to: { name: "UserList" },
            text: "Пользователи",
            visible: +this.$can(["user.view"]),
          },
          {
            to: { name: "RoleList" },
            text: "Роли",
            visible: +this.$can(["role.update"]),
          },
          {
            to: { name: "PermissionList" },
            text: "Разрешения",
            visible: +this.$can(["permission.update"]),
          },
          {
            to: { name: "ApiApSettings" },
            text: "API AP",
            visible: +this.$can(["settings.api-ap.update"]),
          },
          {
            to: { name: "CadastralsSettings" },
            text: "База Кадастров",
            visible: +this.$can(["settings.cadastrals.update"]),
          },
        ];

        return items.filter((item) => item.visible);
      },

      disableBreadcrumbsForRoutes() {
        return ["BoxEdit", "ApProjectEdit"];
      },
    },

    methods: {
      logout() {
        this.$store.dispatch(`auth/${authActions.logoutUser}`).then((res) => {
          if (res.status === 204) {
            this.$router.push({ name: "Login" }).catch((err) => {
              console.warn(err);
            });
          }
        });
      },
    },
  };
</script>
