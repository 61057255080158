import auth from "@/router/middlewares/auth";
import checkPermissions from "@/router/middlewares/checkPermissions";

import Users from "@/pages/settings/users/Users";
import Permissions from "@/pages/settings/permissions/Permissions";
import Roles from "@/pages/settings/roles/Roles";
import Settings from "@/pages/settings/Settings";
import Regions from "@/pages/settings/regions/Regions";
import ApiApSettings from "@/pages/settings/ApiApSettings";

// nested
import userRoutes from "./settings/userRoutes";
import permissionRoutes from "./settings/permissionRoutes";
import roleRoutes from "./settings/roleRoutes";
import regionRoutes from "./settings/regionRoutes";
import CadastralsSettings from "../../pages/settings/CadastralsSettings.vue";

const settingsRoutes = [
  {
    path: "/settings",
    component: Settings,
    beforeEnter: [auth, checkPermissions],
    children: [
      {
        path: "",
        redirect: { name: "UserList" },
      },
      {
        path: "regions",
        component: Regions,
        meta: {
          layout: "main",
          permissions: ["region.update"],
          crumbs: "/Настройки/Регионы",
        },
        children: regionRoutes,
      },
      {
        path: "users",
        component: Users,
        meta: {
          layout: "main",
          permissions: ["user.update"],
          crumbs: "/Настройки/Пользователи",
        },
        children: userRoutes,
      },
      {
        path: "roles",
        component: Roles,
        meta: {
          layout: "main",
          permissions: ["role.update"],
          crumbs: "/Настройки/Роли",
        },
        children: roleRoutes,
      },
      {
        path: "permissions",
        component: Permissions,
        meta: {
          layout: "main",
          permissions: ["permission.update"],
          crumbs: "/Настройки/Разрешения",
        },
        children: permissionRoutes,
      },
      {
        path: "api-ap",
        name: "ApiApSettings",
        component: ApiApSettings,
        meta: {
          layout: "main",
          permissions: ["settings.api-ap.update"],
          crumbs: "/Настройки/API AP",
        },
      },
      {
        path: "cadastrals",
        name: "CadastralsSettings",
        component: CadastralsSettings,
        meta: {
          layout: "main",
          permissions: ["settings.cadastrals.update"],
          crumbs: "/Настройки/База Кадастров",
        },
      },
    ],
  },
];

export default settingsRoutes;
