<template>
  <div class="l-table__row" role="rowgroup">
    <div :style="getCellStyle('status')" class="l-table__cell">
      <q-select
        v-model="search.status"
        :options="['ok', 'warning', 'error']"
        clearable
        dense
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('message')" class="l-table__cell">
      <q-input
        v-model="search.message.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.message.c"
            :value="search.message.c"
            :options="compareStringOptions"
            @update:model-value="onSearchCompareInput(search.message.v)"
          />
        </template>
      </q-input>
    </div>

    <div :style="getCellStyle('created_at')" class="l-table__cell">
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.created_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.created_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.created_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerCreatedFrom">
                <q-date
                  v-model="search.created_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerCreatedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.created_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.created_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.created_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerCreatedTo">
                <q-date
                  v-model="search.created_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerCreatedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div :style="getCellStyle('tags')" class="l-table__cell">
      <q-select
        v-model="search.tags"
        :options="['job']"
        clearable
        dense
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('region_name')" class="l-table__cell">
      <q-select
        v-model="search.region_name"
        :options="[
          'Алтайский край',
          'Амурская область',
          'Владимирская область',
          'Волгоградская область',
          'Вологодская область',
          'Воронежская область',
          'Забайкальский край',
          'Иркутская область',
          'Калининградская область',
          'Калужская область',
          'Кировская область',
          'Краснодарский край',
          'Красноярский край',
          'Курганская область',
          'Ленинградская область',
          'Москва',
          'Московская область',
          'Нижегородская область',
          'Новосибирская область',
          'Омская область',
          'Пермский край',
          'Приморский край',
          'Псковская область',
          'Республика Адыгея',
          'Республика Башкортостан',
          'Республика Бурятия',
          'Республика Крым',
          'Республика Саха (Якутия)',
          'Республика Татарстан',
          'Ростовская область',
          'Рязанская область',
          'Самарская область',
          'Санкт-Петербург',
          'Саратовская область',
          'Сахалинская область',
          'Свердловская область',
          'Севастополь',
          'Смоленская область',
          'Тверская область',
          'Тульская область',
          'Тюменская область',
          'Удмуртская Республика',
          'Ульяновская область',
          'Хабаровский край',
          'Ханты-Мансийский автономный округ - Югра',
          'Челябинская область',
          'Ямало-Ненецкий автономный округ',
          'Ярославская область',
        ]"
        clearable
        dense
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('context')" class="l-table__cell" />
  </div>
</template>

<script>
  import ComparisonSelect from "@/components/ComparisonSelect";
  import { COMPARE_STRING_OPTIONS, SEARCH_INPUT_PROPS } from "@/utils/batch";

  export default {
    name: "LogsTableFiltersRow",

    emits: ["filter-logs", "set-logs"],

    props: {
      cellStyles: {
        type: Object,
        required: true,
      },
      search: {
        type: Object,
        required: true,
      },
    },

    components: {
      ComparisonSelect,
    },

    data() {
      return {
        searchInputProps: SEARCH_INPUT_PROPS,
        compareStringOptions: COMPARE_STRING_OPTIONS,
        logs: [],
      };
    },

    watch: {
      "search.created_at.from"() {
        this.$emit("filter-logs");
      },
      "search.created_at.to"() {
        this.$emit("filter-logs");
      },
    },

    async mounted() {
      this.$emit("set-logs");
    },

    methods: {
      onSearchInput(val) {
        this.$emit("filter-logs");
      },

      onSearchCompareInput(val) {
        if (!val) {
          return;
        }

        this.$emit("filter-logs");
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },
    },
  };
</script>
