import { axiosInstance as axios } from "@/plugins/axios";

const settings = {
  findPd() {
    return axios.get("/settings/pd");
  },

  findApiAp() {
    return axios.get("/settings/api-ap");
  },

  findCadastrals() {
    return axios.get("/settings/cadastrals");
  },

  updatePd(payload) {
    return axios.patch("/settings/pd", payload);
  },

  updateApiAp(payload) {
    return axios.patch("/settings/api-ap", payload);
  },
  updateCadastrals(payload) {
    return axios.patch("/settings/cadastrals", payload);
  },
};

export default settings;
